import { Box, Button, Stack } from "@mui/material";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import attendanceService from "../services/attendance";
import divisionService from "../services/division";
import employeeService from "../services/employee";
import WebcamCapture from "./WebcamCapture";

import { useNavigate } from "react-router-dom";

function calcCrow(lat1: number, lon1: number, lat2: number, lon2: number) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000;
}

function toRad(Value: number) {
  return (Value * Math.PI) / 180;
}

export default function Checkin() {
  const user = JSON.parse(CookieJs.get("USER") ?? "");
  const [currentPosition, setCurrentPosition] = useState({
    latitude: 0,
    longitude: 0,
    branchLatitude: 0,
    branchLongitude: 0,
  });

  const [options, setOptions] = useState<any>({
    date: new Date(),
    userid: user.id,
    distance: 0,
    employeeid: 0,
  });

  const [isCheckin, setIsCheckin] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const getCurrentPosition = () => {
    if (currentPosition.latitude === 0 && currentPosition.longitude === 0) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const coords = pos.coords;
          setCurrentPosition((prev: any) => ({
            ...prev,
            latitude: coords.latitude,
            longitude: coords.longitude,
          }));
        },
        (error) => {
          console.log(error.code, error.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 10000,
        }
      );
    }
  };

  useEffect(() => {
    employeeService.getEmployeeByUserId(user.id).then((response) => {
      setOptions({ ...options, employeeid: response.data.id });
    });
    getCurrentPosition();
    attendanceService.getSpecificAttendance(options).then((response) => {
      if (response.data.length !== 0) {
        setIsCheckin(false);
        if (response.data[0].is_checkout) {
          setIsCompleted(true);
        }
      }
    });
    divisionService.getDivisionByEmployee(options).then((response) => {
      if (options.date.getHours() > response.data.close_time.split(":")[0]) {
        setIsClosed(true);
      } else if (
        options.date.getHours() >= response.data.close_time.split(":")[0]
      ) {
        if (
          options.date.getMinutes() > response.data.close_time.split(":")[1] ||
          (options.date.getMinutes() >=
            response.data.close_time.split(":")[1] &&
            options.date.getSeconds() > response.data.close_time.split(":")[2])
        ) {
          setIsClosed(true);
        }
      }
      setCurrentPosition((prev: any) => ({
        ...prev,
        branchLongitude: response.data.location.y,
        branchLatitude: response.data.location.x,
      }));
    });
  }, []);

  useEffect(() => {
    const distance = calcCrow(
      currentPosition.latitude,
      currentPosition.longitude,
      currentPosition.branchLatitude,
      currentPosition.branchLongitude
    );
    setOptions((prev: any) => ({
      ...prev,
      distance: distance,
    }));
  }, [currentPosition]);

  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(`/app/attendance/dashboard`);
  };
  const handleDetail = () => {
    navigate(`/app/employee/${options.employeeid}`);
  };
  return (
    <MainLayout>
      <Helmet>
        {isClosed && isCheckin ? (
          <title>Closed</title>
        ) : isCompleted ? (
          <title>Completed</title>
        ) : isCheckin ? (
          <title>Checkin</title>
        ) : (
          <title>Checkout</title>
        )}
      </Helmet>

      <Breadcrumb
        label={
          isClosed && isCheckin
            ? "Closed for today"
            : isCompleted
            ? "Already Checked Out"
            : isCheckin
            ? "Checkin"
            : "Checkout"
        }
        breadcrumbs={[{ label: "Checkin / Checkout", href: "/app/checkin" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[6]}>
        <Box sx={{ mt: 2 }}>
          {isCompleted || (isClosed && isCheckin) ? (
            <></>
          ) : (
            <Stack direction="row" spacing={1}>
              <WebcamCapture isCheckin={isCheckin} options={options} currentPosition={currentPosition} />
            </Stack>
          )}
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" onClick={() => handleOnClick()}>
            Check History
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" onClick={() => handleDetail()}>
            My Profile
          </Button>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
