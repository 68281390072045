import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import get from "lodash/get";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import group from "../../services/group";
import report from "../../services/report";
import queryDefault from "../../utils/queryDefault";
import ReportTable from "./ReportTable";

export default function AllReport() {
  const [valueFrom, setValueFrom] = useState<Dayjs | null>(dayjs(new Date()));
  const [valueTo, setValueTo] = useState<Dayjs | null>(dayjs(new Date()));
  const defaultValues = {
    group_id: 0,
    date_from: Moment(new Date()).format('YYYY-MM-DD'),
    date_to: Moment(new Date()).format('YYYY-MM-DD'),
  }
  const form = useForm({ defaultValues });

  const [options, setOptions] = useState<any>(defaultValues);
  const viewAllReport = useQuery(
    ["viewreport", options],
    ({ queryKey }) => {
      return report.viewAllReport({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const groups = useQuery(
    ["groups"],
    () => {
      return group.getAllBranchGroup({ keyword: "" });
    },
    {
      ...queryDefault,
    }
  );

  const [redColumns, setRedColumns] = useState<any[]>([]);

  function evaluateRedColumns() {
    Object.values(get(viewAllReport, "data.data", [])).forEach((report: any) => {
      Object.entries(report).forEach(([key, value]: any) => {
        if (!isNaN(value) && Number(value) === -1) {
          setRedColumns(current => [...current, key]);
        }
      })
    });
  }

  useEffect(() => {
    form.setValue("date_from", Moment(valueFrom?.format('YYYY-MM-DD')).format('YYYY-MM-DD'));
    form.setValue("date_to", Moment(valueTo?.format('YYYY-MM-DD')).format('YYYY-MM-DD'));
    setOptions({ ...options, date_from: form.getValues().date_from, date_to: form.getValues().date_to });
  }, [valueFrom, valueTo, form]);

  useEffect(() => {
    viewAllReport.refetch();
  }, [options]);

  useEffect(() => {
    setRedColumns([]);
    evaluateRedColumns();
  }, [viewAllReport]);

  const onSubmit = (values: any) => {
    report.getAllReport(values);
  };

  function capitalizeFLetter(input: string) {
    return input[0].toUpperCase() + input.slice(1);
  }

  return (
    <MainLayout>
      <Breadcrumb
        label={"All Report"}
        breadcrumbs={[
          { label: "Report", href: "/app/report" },
          {
            label: "All Report",
            href: "/app/report/all",
          },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1}>
            <FormControl margin="normal">
              <FormLabel>Group</FormLabel>
              <Controller
                name="group_id"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="group_id"
                    value={form.getValues().group_id}
                    onChange={(event) => {
                      form.setValue("group_id", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        group_id: Number(event.target.value)
                      }));
                    }}
                    defaultValue={form.getValues().group_id ? form.getValues().group_id : 0}
                  >
                    <MenuItem key={0} value={0}>
                      All Group
                    </MenuItem>
                    {get(groups, "data.data", []).map(
                      (data: any, index: number) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl margin="normal">
              <FormLabel>Date From</FormLabel>
              <Controller
                name="date_from"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      views={["day", "month", "year"]}
                      value={valueFrom}
                      onChange={newValue => {
                        setValueFrom(newValue);
                      }}
                      renderInput={props => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>
            <FormControl margin="normal">
              <FormLabel>Date To</FormLabel>
              <Controller
                name="date_to"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      views={["day", "month", "year"]}
                      value={valueTo}
                      onChange={newValue => {
                        setValueTo(newValue);
                      }}
                      renderInput={props => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>
          </Stack>

          <Box sx={{ mt: 2 }}>
            <Button
              style={{ marginBottom: "20px" }}
              variant="outlined"
              onClick={form.handleSubmit(onSubmit)}
            >
              Generate Report
            </Button>
          </Box>
        </Box>
        <ReportTable
          head={viewAllReport.isLoading ? [<th className="greenBackground" key="name">Name</th>]
            : Object.keys(get(viewAllReport, "data.data", ["name"])[0])
              .map((data: any) => {
                return <th className={redColumns.includes(data) ? "redBackground" : "greenBackground"} key={data}>
                  {data.split("_").length === 2 ? capitalizeFLetter(data.split("_")[1]) : data}
                </th>
              })}
          body={viewAllReport.isLoading ? <tr><td>Loading, please wait</td></tr> : get(viewAllReport, "data.data", []).map((data: any) => {
            console.log(data.name)
            return (
              <tr>
                {
                  Object.keys(get(viewAllReport, "data.data", ["name"])[0]).map((v: any) => {
                    if (data[v] === -1) {
                      return <td key={data.name} className="redBackground">NS</td>
                    } else {
                      return <td key={data.name}>{data[v]}</td>
                    }
                  })
                }
              </tr>
            )
          })}>
        </ReportTable>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
