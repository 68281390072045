import { Box, FormControl, FormLabel, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import branch from "../../services/branch";
import service from "../../services/group";
import queryDefault from "../../utils/queryDefault";
import { BarChart } from "@mui/x-charts";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function BranchGroupDetail() {
  const location = useLocation();
  const path = location.pathname.split("/");
  const group_id = path.pop() || "";

  const currentGroup = useQuery(
    ["current", group_id],
    () => service.getDetailBranchGroup(group_id),
    {
      ...queryDefault,
    }
  );

  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  let thisYear = new Date().getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 5; x++) {
    allYears.push(thisYear - x);
  }


  const [countStats, setCountStats] = useState([
    { grouped_date: "", total_kertas: 0 },
  ]);
  const [salesStats, setSalesStats] = useState([
    { grouped_date: "", subtotal: 0 },
  ]);
  const chartSetting = {
    height: 300,
  };

  const [options, setOptions] = useState<any>({
    group_id: group_id,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  });

  const listBranchStats = useQuery(
    ["stats", options],
    ({ queryKey }) => {
      return branch.getBranchStats({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  useEffect(() => {
    if (listBranchStats.data?.data?.count?.length > 0) {
      var countStats: any = [];
      for (const i of listBranchStats.data.data.count) {
        countStats.push({
          grouped_date: `${i.grouped_date.split('-')[2]}-${i.grouped_date.split('-')[1]}`,
          total_kertas: Number(i.total_kertas)
        });
      }
      setCountStats(countStats);
    } else {
      setCountStats([]);
    }
    if (listBranchStats.data?.data?.sales?.length > 0) {
      var salesStats: any = [];
      for (const i of listBranchStats.data.data.sales) {
        salesStats.push({
          grouped_date: `${i.grouped_date.split('-')[2]}-${i.grouped_date.split('-')[1]}`,
          subtotal: Number(i.subtotal)
        });
      }
      setSalesStats(salesStats);
    } else {
      setSalesStats([]);
    }
  }, [listBranchStats]);

  useEffect(() => {
    setOptions({
      ...options,
      month: month,
      year: year
    })
  }, [month, year]);

  return (
    <MainLayout>
      <Helmet>
        <title>Group Detail</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Group", href: "/app/branch-group" }]}
        label={currentGroup.data?.data?.name}
        href={`/app/branch-group/`}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Name
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {currentGroup.data?.data?.name}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="h2">Stats</Typography>

          <Box my={2}>
            <Stack direction="row" spacing={1}>
              <FormControl>
                <FormLabel id="month-label">Month</FormLabel>
                <Select
                  labelId="month-label"
                  value={month}
                  onChange={(e) => setMonth(e.target.value as number)}
                >
                  <MenuItem value={0}>Select Month</MenuItem>
                  <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel id="year-label">Year</FormLabel>
                <Select
                  labelId="year-label"
                  value={year}
                  onChange={(e) => setYear(e.target.value as number)}
                >
                  <MenuItem key={0} value={0}>
                    Select Year
                  </MenuItem>
                  {allYears.map((x) => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>
          <Typography variant="h3" sx={{ mt: 2 }}>Total Kertas</Typography>
          <BarChart
            sx={{ "& .MuiChartsAxis-tickLabel tspan": { fontSize: "10px" } }}
            dataset={countStats}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "grouped_date",
                label: "Date-Month",
              },
            ]}
            series={[{ dataKey: "total_kertas", label: "Total" }]}
            {...chartSetting}
          />
          <Typography variant="h3" sx={{ mt: 2 }}>Revenue</Typography>
          <BarChart
            sx={{ "& .MuiChartsAxis-tickLabel tspan": { fontSize: "10px" } }}
            dataset={salesStats}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "grouped_date",
                label: "Date-Month",
              },
            ]}
            series={[{ dataKey: "subtotal", label: "Total" }]}
            {...chartSetting}
          />
        </Box>

        <Typography sx={{
          fontWeight: 'bold',
          mt: 2
        }}>Branches</Typography>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={currentGroup.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(currentGroup, "data.branches", [])}
            rowCount={get(currentGroup, "data.branches", []).length}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}

            columns={[
              {
                field: "name",
                headerName: "Branch Name",
                flex: 1,
                filterable: false,
                minWidth: 150,
              }
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
