export const API_URL = process.env.REACT_APP_BACKEND_URL
const endpoints = {
  // Auth
  BASE_API: API_URL + "/api/v1/",
  SIGN_IN: API_URL + "/api/v1/auth/signin",
  PROFILE: (params: any) => API_URL + `/api/v1/users/${params}`,
  FORGOT_PASSWORD: API_URL + "/api/v1/admin/forgot-password",
  RESET_PASSWORD: API_URL + "/api/v1/admin/reset-password",
  EDIT_PROFILE: (userId: any) => API_URL + `/api/v1/users/${userId}`,
  CHANGE_PASSWORD: API_URL + "/api/v1/users/change-password",

  // Role
  VIEW_LIST_ROLE: API_URL + "/api/v1/roles",

  // Users
  VIEW_LIST_USER: API_URL + "/api/v1/users",
  SEARCH_LIST_USER: API_URL + "/api/v1/admin/search-users",
  CREATE_USER: API_URL + "/api/v1/users",
  VIEW_USER: (viewId: any) => API_URL + `/api/v1/users/${viewId}`,

  // Employee
  EMPLOYEE_URL: API_URL + "/api/v1/employee",
  VIEW_EMPLOYEE: (params: any) => API_URL + `/api/v1/employee/${params}`,
  VIEW_EMPLOYEE_BY_USER: (params: any) => API_URL + `/api/v1/employee/byuser/${params}`,

  // BRANCH
  BRANCH: API_URL + "/api/v1/branch",
  DETAIL_BRANCH: (id: number) => API_URL + `/api/v1/branch/${id}`,
  
  // BRANCH Group
  BRANCH_GROUP: API_URL + "/api/v1/branch-group",
  DETAIL_BRANCH_GROUP: (id: number) => API_URL + `/api/v1/branch-group/${id}`,
  
  // Employee Group
  EMPLOYEE_GROUP: API_URL + "/api/v1/employee-group",
  DETAIL_EMPLOYEE_GROUP: (id: number) => API_URL + `/api/v1/employee-group/${id}`,

  DIVISION: API_URL + "/api/v1/division",
  DETAIL_DIVISION: (id: number) => API_URL + `/api/v1/division/${id}`,

  //report
  REPORT:  API_URL + `/api/v1/report`,
  REPORT_DOWNLOAD:  API_URL + `/api/v1/report/download`,
  REPORT_UPLOAD:  API_URL + `/api/v1/report/upload`,
  REPORT_UPLOAD_XENDIT:  API_URL + `/api/v1/report/upload-xendit`,
  REPORT_UPLOAD_MIDTRANS:  API_URL + `/api/v1/report/upload-midtrans`,
  DETAIL_REPORT: (id: number) => API_URL + `/api/v1/report/${id}`,
  ALL_REPORT: (params: string) => API_URL + `/api/v1/report/all/${params}`,
  VIEW_ALL_REPORT: (params: string) => API_URL + `/api/v1/report/viewall/${params}`,

  //sales
  SALES:  API_URL + `/api/v1/sales`,
  DETAIL_SALES: (id: number) => API_URL + `/api/v1/sales/${id}`,
};

export default endpoints;
