import { Close, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import CookieJs from "js-cookie";
import get from "lodash/get";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useAlert from "../../hooks/useAlert";
import useNotification from "../../hooks/useNotification";
import attendance from "../../services/attendance";
import service from "../../services/employee";
import config from "../../utils/config";
import queryDefault from "../../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflow: "scroll"
};

export default function EmployeeList() {
  const user = JSON.parse(CookieJs.get("USER") ?? "");
  const [openName, setOpenName] = useState(false);
  const handleOpenName = () => setOpenName(true);
  const handleCloseName = () => setOpenName(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [options, setOptions] = useState<any>({
    keyword: "",
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const notification = useNotification();
  const sendBulkEmail = useMutation("send_email", attendance.sendEmailBulk, {
    onSuccess: () => {
      setOpenName(false);
      notification.onOpen({
        message: "Success Start Send Email",
        type: "success",
        position: "top",
      });
    },
    onError: () => {
      setOpenName(false);
      notification.onOpen({
        message: "Something went wrong",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const deleteEmployee = useMutation(
    "delete-employee",
    service.deleteEmployee,
    {
      onSuccess: () => {
        listEmployee.refetch();
        notification.onOpen({
          message: "Employee was successfully deleted!",
          type: "success",
          position: "top",
        });
      },
      onError: (error: any) => {
        listEmployee.refetch();
        notification.onOpen({
          message:
            error?.response?.data?.message === undefined
              ? "Something went wrong"
              : error?.response?.data?.message,
          type: "error",
          position: "top",
        });
      },
      ...queryDefault,
    }
  );

  const onDelete = (id: number) => {
    alert.onClose();
    deleteEmployee.mutate({
      id: id,
      admin_name: user.username,
    });
  };

  const alert = useAlert();
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete this employee?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const listEmployee = useQuery(
    ["employeelist", options],
    ({ queryKey }) => {
      return service.getEmployee({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const form = useForm({
    defaultValues: {
      keyword: "",
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      ids: ""
    },
  });
  let thisYear = new Date().getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 5; x++) {
    allYears.push(thisYear - x);
  }
  const onSubmit = (values: any) => {
    values.ids = rowSelectionModel.join();
    sendBulkEmail.mutate({
      data: values,
    });
  };

  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);

  const navigate = useNavigate();
  const handleOnCellClick = (params: GridCellParams) => {
    const { row } = params;
    navigate(`/app/attendance/${row.id}`, { state: row });
  };

  const uploadBulk = useMutation("upload_bulk", service.uploadBulk, {
    onSuccess: () => window.location.reload()
  });

  const uploadBulkAdjustment = useMutation("upload_bulk_adjustment", service.uploadBulkAdjustment, {
    onSuccess: () => window.location.reload()
  });

  const handleUploadBulk = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("admin_name", user.username);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadBulk.mutate(formData);
  };

  const handleUploadBulkAdjustment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("admin_name", user.username);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadBulkAdjustment.mutate(formData);
  };

  const getUsernameFromId = (id: any) => listEmployee.data.data.find((x: any) => x.id === id).username;
  return (
    <MainLayout>
      <Helmet>
        <title>Employee List</title>
      </Helmet>

      <Breadcrumb
        label="Employee List"
        breadcrumbs={[{ label: "Employee Management", href: "/app/employee" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[4, 5]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              SEARCH
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>

      <Box sx={{ mt: 2 }}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            component={RouterLink}
            to="/app/employee/create-employee"
          >
            Add Employee
          </Button>
          <Button variant="contained" onClick={handleOpenName}>
            Email Bulk Salary
          </Button>
          <Button variant="contained" onClick={() => service.exportEmployee()}>
            Export Employee
          </Button>
          <LoadingButton variant="contained" component="label" loading={uploadBulk.isLoading}>
            Upload Bulk Employee <input onChange={handleUploadBulk} type="file" hidden />
          </LoadingButton>
          <Button variant="outlined" onClick={() => service.getBulkAdjustmentTemplate()}>
            Bulk Adjustment Template
          </Button>
          <LoadingButton variant="outlined" component="label" loading={uploadBulkAdjustment.isLoading}>
            Upload Bulk Adjustment <input onChange={handleUploadBulkAdjustment} type="file" hidden />
          </LoadingButton>
        </Stack>
      </Box>

      <Modal
        open={openName}
        onClose={handleCloseName}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row" spacing={1}>
            <FormControl margin="normal">
              <FormLabel>Month</FormLabel>
              <Controller
                name="month"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="month"
                    value={form.getValues().month}
                    onChange={(event) => {
                      form.setValue("month", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        month: Number(event.target.value),
                      }));
                    }}
                    defaultValue={form.getValues().month}
                  >
                    <MenuItem value={0}>Select Month</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Year</FormLabel>
              <Controller
                name="year"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="year"
                    value={form.getValues().year}
                    onChange={(event) => {
                      form.setValue("year", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        year: Number(event.target.value),
                      }));
                    }}
                    defaultValue={form.getValues().year}
                  >
                    <MenuItem key={0} value={0}>
                      Select Year
                    </MenuItem>
                    {allYears.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Stack>

          <Box sx={{ mt: 2 }}>
            <Typography>Recipients:</Typography>
            {rowSelectionModel.map((id) => (
              <Typography>{getUsernameFromId(id)}</Typography>
            ))}
          </Box>

          <LoadingButton
            sx={{ marginTop: "5px" }}
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
            loading={sendBulkEmail.isLoading}
          >
            SEND
          </LoadingButton>
        </Box>
      </Modal>

      <Box sx={{ mt: 2 }}>
        <DataGrid
          autoHeight
          loading={listEmployee.isLoading}
          getRowId={(record) => get(record, "id")}
          rows={get(listEmployee, "data.data", [])}
          rowCount={get(listEmployee, "data.data", []).length}
          page={options.page_number}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 30, 50, 100]}
          pagination
          checkboxSelection
          onSelectionModelChange={(newRowSelectionModel: any) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          selectionModel={rowSelectionModel}
          onPageChange={(newPage) => {
            setOptions((prev: any) => ({ ...prev, page_number: newPage }));
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            NoRowsOverlay: CustomNoRowOverlay,
          }}
          columns={[
            {
              field: "group_name",
              headerName: "Group",
              flex: 1,
              filterable: false,
              minWidth: 150,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "username",
              headerName: "Username",
              flex: 1,
              filterable: false,
              minWidth: 150,
              renderCell: (params) => {
                return (
                  <Stack onClick={() => handleOnCellClick(params)}>
                    <Typography color="blue">{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "name",
              headerName: "Division",
              flex: 1,
              filterable: false,
              minWidth: 150,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
              filterable: false,
              minWidth: 150,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "actions",
              headerName: "Action",
              sortable: false,
              filterable: false,
              width: 200,
              renderCell: (params) => {
                return (
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => navigate('/app/attendance/dashboard', { state: { id: params.row.userid } })}
                    >
                      Dashboard
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      component={RouterLink}
                      to={`/app/employee/edit/${get(params, "row.id")}`}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => onClickDelete(params.row.id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                );
              },
            },
          ]}
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: 700,
            },
          }}
        />
      </Box>
    </MainLayout>
  );
}
