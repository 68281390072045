import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const viewAllApproval = async () => {
  const url = endpoints.BASE_API + "approval"
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
};

const viewAllApprovalPending = async () => {
  const url = endpoints.BASE_API + "approval/pending"
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
};

const updateApprovalStatus = async (params: any) => {
  const url = endpoints.BASE_API + "approval/" + params.id
    const { data: response } = await axios.post(url, params, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
};

const service = {
  viewAllApproval,
  viewAllApprovalPending,
  updateApprovalStatus
};

export default service;
