import { Box, Button, FormControl, FormLabel, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { get } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useAlert from "../../hooks/useAlert";
import useNotification from "../../hooks/useNotification";
import branch from "../../services/branch";
import initial from "../../utils/initial";
import queryDefault from "../../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function BranchDetail() {
  const [pageSize, setPageSize] = useState<number>(10);
  const location = useLocation();
  const path = location.pathname.split("/");
  const branch_id = path.pop() || "";
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  let thisYear = new Date().getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 5; x++) {
    allYears.push(thisYear - x);
  }

  const [options, setOptions] = useState<any>({
    branch_id: branch_id,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  });

  const [data, setData] = useState({
    id: branch_id,
    name: "",
    place: "",
    email: "",
    priceperunit: 0,
    issplitrevenue: false,
    splitrevenuepercentage: 0,
    isqrauto: false,
    mdr: 0,
    isppn: false,
    ppn: 0,
    minsplitrevenue: 0,
    reference_xendit: "",
    reference_midtrans: "",
    bank_rekening: "",
    no_rekening: "",
    atas_nama: "",
    npwp: "",
    group: "",
    npwp_image: initial.IMAGE_NPWP
  });

  const [countStats, setCountStats] = useState([
    { grouped_date: "", total_kertas: 0 },
  ]);
  const [salesStats, setSalesStats] = useState([
    { grouped_date: "", subtotal: 0 },
  ]);
  const chartSetting = {
    height: 300,
  };

  const listSplitRevenue = useQuery(
    ["revenue", options],
    ({ queryKey }) => {
      return branch.getAllSplitRevenue({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const listBranchStats = useQuery(
    ["stats", options],
    ({ queryKey }) => {
      return branch.getBranchStats({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  useEffect(() => {
    branch.getDetailBranch(branch_id).then((response) => {
      setData({
        id: branch_id,
        name: response.data.name,
        place: response.data.place,
        email: response.data.email,
        priceperunit: response.data.priceperunit,
        issplitrevenue: response.data.issplitrevenue,
        splitrevenuepercentage: response.data.splitrevenuepercentage,
        isqrauto: response.data.isqrauto,
        mdr: response.data.mdr,
        isppn: response.data.isppn,
        ppn: response.data.ppn,
        minsplitrevenue: response.data.minsplitrevenue,
        reference_xendit: response.data.reference_xendit,
        reference_midtrans: response.data.reference_midtrans,
        bank_rekening: response.data.bank_rekening,
        no_rekening: response.data.no_rekening,
        atas_nama: response.data.atas_nama,
        npwp: response.data.npwp,
        group: response.data.group_name,
        npwp_image: !response.data.npwp_image || response.data.npwp_image === ""
          ? initial.IMAGE_NPWP
          : "data:image/png;base64," + response.data.npwp_image
      });
    });
  }, [branch_id]);

  useEffect(() => {
    if (listBranchStats.data?.data?.count?.length > 0) {
      var countStats: any = [];
      for (const i of listBranchStats.data.data.count) {
        countStats.push({
          grouped_date: `${i.grouped_date.split('-')[2]}-${i.grouped_date.split('-')[1]}`,
          total_kertas: Number(i.total_kertas)
        });
      }
      setCountStats(countStats);
    } else {
      setCountStats([]);
    }
    if (listBranchStats.data?.data?.sales?.length > 0) {
      var salesStats: any = [];
      for (const i of listBranchStats.data.data.sales) {
        salesStats.push({
          grouped_date: `${i.grouped_date.split('-')[2]}-${i.grouped_date.split('-')[1]}`,
          subtotal: Number(i.subtotal)
        });
      }
      setSalesStats(salesStats);
    } else {
      setSalesStats([]);
    }
  }, [listBranchStats]);

  useEffect(() => {
    setOptions({
      ...options,
      month: month,
      year: year
    })
  }, [month, year]);

  const notification = useNotification();
  const alert = useAlert();
  const deleteSales = useMutation("delete-sales", branch.deleteSplitRevenue, {
    onSuccess: () => {
      listSplitRevenue.refetch();
      notification.onOpen({
        message: "Split Revenue was successfully deleted!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onDelete = (id: number) => {
    alert.onClose();
    deleteSales.mutate(id);
  };

  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete Split Revenue?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const navigate = useNavigate();
  const handleOnCellClick = (id: number) => {
    navigate(`/app/branch/split-revenue/${id}`);
  };

  const uploadNPWP = useMutation("upload_template", branch.uploadNPWP, {
    onSuccess: () => window.location.reload()
  });

  const handleUploadNPWP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("id", data.id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadNPWP.mutate(formData);
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Branch Detail</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Branch", href: "/app/branch" }]}
        label={data.name}
        href={`/app/branch/`}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Name
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.name}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Group
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.group}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Place
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.place}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Email
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.email}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Price Per Unit
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.priceperunit}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Split Revenue?
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.issplitrevenue ? "Yes" : "No"}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Split Revenue %
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.splitrevenuepercentage}
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Minimal Split Revenue
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.minsplitrevenue}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  QR Type
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.isqrauto ? "Auto" : "Manual"}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  MDR %
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.mdr}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Use PPN?
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.isppn ? "Yes" : "No"}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  PPN %
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.ppn}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Reference for Xendit Report
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.reference_xendit}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Reference for Midtrans Report
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.reference_midtrans}
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Rekening
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {`${data.bank_rekening} ${data.no_rekening} a/n ${data.atas_nama}`}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  NPWP
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3, mb: 2 }}
                >
                  {data.npwp}
                </Typography>
                <img src={data.npwp_image} alt="npwp" width="100%" />
                <Button variant="contained" component="label" sx={{ mt: 2 }}>
                  Upload NPWP <input onChange={handleUploadNPWP} type="file" hidden />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="h2">Stats</Typography>

          <Box my={2}>
            <Stack direction="row" spacing={1}>
              <FormControl>
                <FormLabel id="month-label">Month</FormLabel>
                <Select
                  labelId="month-label"
                  value={month}
                  onChange={(e) => setMonth(e.target.value as number)}
                >
                  <MenuItem value={0}>Select Month</MenuItem>
                  <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel id="year-label">Year</FormLabel>
                <Select
                  labelId="year-label"
                  value={year}
                  onChange={(e) => setYear(e.target.value as number)}
                >
                  <MenuItem key={0} value={0}>
                    Select Year
                  </MenuItem>
                  {allYears.map((x) => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>
          <Typography variant="h3" sx={{ mt: 2 }}>Total Kertas</Typography>
          <BarChart
            sx={{ "& .MuiChartsAxis-tickLabel tspan": { fontSize: "10px" } }}
            dataset={countStats}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "grouped_date",
                label: "Date-Month",
              },
            ]}
            series={[{ dataKey: "total_kertas", label: "Total" }]}
            {...chartSetting}
          />
          <Typography variant="h3" sx={{ mt: 2 }}>Revenue</Typography>
          <BarChart
            sx={{ "& .MuiChartsAxis-tickLabel tspan": { fontSize: "10px" } }}
            dataset={salesStats}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "grouped_date",
                label: "Date-Month",
              },
            ]}
            series={[{ dataKey: "subtotal", label: "Total" }]}
            {...chartSetting}
          />
        </Box>

        <Typography sx={{
          fontWeight: 'bold',
          mt: 2
        }}>Split Revenue History</Typography>
        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={listSplitRevenue.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listSplitRevenue, "data.data", [])}
            rowCount={get(listSplitRevenue, "data.data", []).length}
            page={options.page_number}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}

            columns={[
              {
                field: "percentage",
                headerName: "Split Revenue Percentage",
                flex: 1,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "effectivedate",
                headerName: "Effective Date",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }
                  return moment(params.value).format("DD/MM/YYYY");
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        onClick={() => handleOnCellClick(params.row.id)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => onClickDelete(params.row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
