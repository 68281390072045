import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getAllAdjustment = async (params: any) => {
  const url = endpoints.BASE_API + "adjustment";
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });
  return response;
};

const getAdjustmentById = async (id: number) => {
  const url = endpoints.BASE_API + "adjustment/" + id;
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const deleteAdjustment = async (id: number) => {
  const url = endpoints.BASE_API + "adjustment/" + id;
  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const createAdjustment = async (params: any) => {
  const url = endpoints.BASE_API + "adjustment";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateAdjustment = async (params: any) => {
  const url = endpoints.BASE_API + "adjustment/" + params.id;
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const attendance = {
  getAdjustmentById,
  getAllAdjustment,
  updateAdjustment,
  deleteAdjustment,
  createAdjustment
};

export default attendance;
