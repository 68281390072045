import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from "js-cookie";
import get from "lodash/get";
import moment from "moment";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import branch from "../../services/branch";
import group from "../../services/group";
import report from "../../services/report";
import sales from "../../services/sales";
import queryDefault from "../../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function Report() {
  const notification = useNotification();
  const [loadingBulk, setLoadingBulk] = useState(false);
  const [loadingXendit, setLoadingXendit] = useState(false);
  const [loadingMidtrans, setLoadingMidtrans] = useState(false);
  const reportCache =
    CookieJs.get("REPORT_PAGE") === undefined
      ? {
        branchid: 0,
        group_id: 0,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      }
      : JSON.parse(CookieJs.get("REPORT_PAGE") || "");
  const [pageSize, setPageSize] = useState<number>(10);
  const [keyword] = useState<any>({
    keyword: "",
  });
  const [options, setOptions] = useState<any>(reportCache);
  const getBranches = useQuery(
    ["branch", keyword],
    ({ queryKey }) => {
      return branch.getAllBranch({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const groups = useQuery(
    ["groups"],
    () => {
      return group.getAllBranchGroup({ keyword: "" });
    },
    {
      ...queryDefault,
    }
  );

  const getReport = useQuery(
    ["report", options],
    ({ queryKey }) => {
      return report.getReport({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const form = useForm({
    defaultValues: reportCache,
  });

  const downloadReport = useMutation("download-report", report.downloadReport);
  const downloadMonthlySales = useMutation("download-monthly-sales", sales.downloadMonthlySales);
  const downloadSplitRevenuePayroll = useMutation("downloadSplitRevenuePayroll", report.splitRevenuePayroll);
  const sendReport = useMutation("send-report", report.sendEmail, {
    onSuccess() {
      notification.onOpen({
        message: "Success Send Email to PIC Branch",
        type: "success",
        position: "top",
      });
    },
  });

  const onHandleDownload = (params: any) => downloadReport.mutate(params);
  const onHandleDownloadMonthlySales = (params: any) => downloadMonthlySales.mutate(params);
  const onHandleDownloadSplitRevenuePayroll = (params: any) => downloadSplitRevenuePayroll.mutate(params);
  const onHandleSendEmail = (params: any) => sendReport.mutate(params);
  const onHandleReset = (params: any) => {
    report.resetReport(params);
    window.location.reload();
  };

  let thisYear = (new Date()).getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 20; x++) {
    allYears.push(thisYear - x);
  }

  const user = JSON.parse(CookieJs.get("USER") ?? "");
  const uploadBulk = useMutation("upload_bulk", report.uploadBulk, {
    onSuccess: () => {
      setLoadingBulk(false);
      notification.onOpen({
        message: "File was successfully uploaded!",
        type: "success",
        position: "top",
      });
      window.location.reload();
    },
    onError: (error: any) => {
      notification.onOpen({
        message: "Something wrong happened when uploading bulk template",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const uploadBulkMidtrans = useMutation("upload_bulk_midtrans", report.uploadBulkMidtrans, {
    onSuccess: () => {
      setLoadingMidtrans(false);
      notification.onOpen({
        message: "File was successfully uploaded!",
        type: "success",
        position: "top",
      });
      window.location.reload();
    },
    onError: (error: any) => {
      notification.onOpen({
        message: "Something wrong happened when uploading bulk template",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const uploadBulkXendit = useMutation("upload_bulk_xendit", report.uploadBulkXendit, {
    onSuccess: () => {
      setLoadingXendit(false);
      notification.onOpen({
        message: "File was successfully uploaded!",
        type: "success",
        position: "top",
      });
      window.location.reload();
    },
    onError: (error: any) => {
      notification.onOpen({
        message: "Something wrong happened when uploading bulk template",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    event.target.files && formData.append("file", event.target.files[0]);
    formData.append("username", user.username);
    setLoadingBulk(true);
    uploadBulk.mutate(formData);
  };

  const handleFileChangeXendit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formDataXendit = new FormData();
    event.target.files && formDataXendit.append("file", event.target.files[0]);
    formDataXendit.append("username", user.username);
    setLoadingXendit(true);
    uploadBulkXendit.mutate(formDataXendit);
  };

  const handleFileChangeMidtrans = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formDataMidtrans = new FormData();
    event.target.files && formDataMidtrans.append("file", event.target.files[0]);
    formDataMidtrans.append("username", user.username);
    setLoadingMidtrans(true);
    uploadBulkMidtrans.mutate(formDataMidtrans);
  };

  return (
    <MainLayout>
      <Helmet>
        <title>REPORT</title>
      </Helmet>

      <Breadcrumb
        label="Report"
        breadcrumbs={[{ label: "Report", href: "/app/report" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2, 3]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Controller
                name="branchid"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="branchid"
                    value={form.getValues().branchid}
                    onChange={(event) => {
                      form.setValue("branchid", Number(event.target.value));
                      CookieJs.set(
                        "REPORT_PAGE",
                        JSON.stringify({
                          branchid: Number(event.target.value),
                          group_id: form.getValues().group_id,
                          month: form.getValues().month,
                          year: form.getValues().year,
                        })
                      );
                      setOptions((prev: any) => ({
                        ...prev,
                        branchid: Number(event.target.value)
                      }));
                      getReport.refetch();
                    }}
                    defaultValue={form.getValues().branchid ? form.getValues().branchid : 0}
                  >
                    <MenuItem key={0} value={0}>
                      Select Branch
                    </MenuItem>
                    {get(getBranches, "data.data", []).map(
                      (data: any, index: number) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Group</FormLabel>
              <Controller
                name="group_id"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="group_id"
                    value={form.getValues().group_id}
                    onChange={(event) => {
                      form.setValue("group_id", Number(event.target.value));
                      CookieJs.set(
                        "REPORT_PAGE",
                        JSON.stringify({
                          branchid: form.getValues().branchid,
                          group_id: Number(event.target.value),
                          month: form.getValues().month,
                          year: form.getValues().year,
                        })
                      );
                      setOptions((prev: any) => ({
                        ...prev,
                        group_id: Number(event.target.value)
                      }));
                      getReport.refetch();
                    }}
                    defaultValue={form.getValues().group_id ? form.getValues().group_id : 0}
                  >
                    <MenuItem key={0} value={0}>
                      Select Group
                    </MenuItem>
                    {get(groups, "data.data", []).map(
                      (data: any, index: number) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Month</FormLabel>
              <Controller
                name="month"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="month"
                    value={form.getValues().month}
                    onChange={(event) => {
                      form.setValue("month", Number(event.target.value));
                      CookieJs.set(
                        "REPORT_PAGE",
                        JSON.stringify({
                          branchid: form.getValues().branchid,
                          group_id: form.getValues().group_id,
                          month: Number(event.target.value),
                          year: form.getValues().year,
                        })
                      );
                      setOptions((prev: any) => ({
                        ...prev,
                        month: Number(event.target.value)
                      }));
                      getReport.refetch();
                    }}
                    defaultValue={form.getValues().month}
                  >
                    <MenuItem value={0}>Select Month</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Year</FormLabel>
              <Controller
                name="year"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="year"
                    value={form.getValues().year}
                    onChange={(event) => {
                      form.setValue("year", Number(event.target.value));
                      CookieJs.set(
                        "REPORT_PAGE",
                        JSON.stringify({
                          branchid: form.getValues().branchid,
                          group_id: form.getValues().group_id,
                          month: form.getValues().month,
                          year: Number(event.target.value),
                        })
                      );
                      setOptions((prev: any) => ({
                        ...prev,
                        year: Number(event.target.value)
                      }));
                      getReport.refetch();
                    }}
                    defaultValue={form.getValues().year}
                  >
                    <MenuItem key={0} value={0}>
                      Select Year
                    </MenuItem>
                    {allYears.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    )
                    )}
                  </Select>
                )}
              />
            </FormControl>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1}>

            <Button
              variant="contained"
              component={RouterLink}
              to="/app/report/create-report"
            >
              Add Report
            </Button>

            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onHandleDownloadMonthlySales)}
              loading={downloadMonthlySales.isLoading}
            >
              Download Monthly Sales
            </LoadingButton>

            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onHandleDownloadSplitRevenuePayroll)}
              loading={downloadSplitRevenuePayroll.isLoading}
            >
              Split Revenue Payroll
            </LoadingButton>

            {getReport?.data?.data?.length > 0 && options.branchid > 0 && (
              <>
                <LoadingButton
                  variant="contained"
                  onClick={form.handleSubmit(onHandleDownload)}
                  loading={downloadReport.isLoading}
                >
                  Download Branch Report
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  onClick={form.handleSubmit(onHandleSendEmail)}
                  loading={sendReport.isLoading}
                >
                  Send Email
                </LoadingButton>

                <Button variant="contained" component="label" onClick={() => {
                  if (window.confirm("Confirm Reset Branch Reports?")) {
                    onHandleReset({
                      branch_id: form.getValues().branchid,
                      month: form.getValues().month,
                      year: form.getValues().year
                    })
                  }
                }}> Reset
                </Button>
              </>
            )}
          </Stack>

          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            <ProtectedPermissionPage acceptPermissions={[1, 2]}>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to={`/app/report/all`}
                >
                  All Report
                </Button>
              </Stack>

              <LoadingButton variant="outlined" component="label" loading={loadingBulk}>
                Upload Bulk Report
                <input onChange={handleFileChange} type="file" hidden />
              </LoadingButton>

              <LoadingButton variant="outlined" component="label" loading={loadingXendit}>
                Upload Bulk Xendit
                <input onChange={handleFileChangeXendit} type="file" hidden />
              </LoadingButton>

              <LoadingButton variant="outlined" component="label" loading={loadingMidtrans}>
                Upload Bulk Midtrans
                <input onChange={handleFileChangeMidtrans} type="file" hidden />
              </LoadingButton>
            </ProtectedPermissionPage>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={getReport.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(getReport, "data.data", [])}
            rowCount={get(getReport, "data.data", []).length}
            page={options.page_number}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "name",
                headerName: "Branch",
                flex: 1,
                filterable: false,
                minWidth: 300,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "date",
                headerName: "Date",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography color="blue">
                        {moment(params.value).format("DD-MM-YYYY")}
                      </Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "quantity",
                headerName: "Quantity",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography color="blue">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "price",
                headerName: "Price",
                flex: 1,
                filterable: false,
                minWidth: 150,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography color="blue">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/app/report/edit/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
