import axios from "axios";
import fileDownload from "js-file-download";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getAttendance = async (params: any) => {
  const url = endpoints.BASE_API + "attendance";
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });
  return response;
};

const getDashboard = async (params: any) => {
  const url = endpoints.BASE_API + "attendance/dashboard";
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });
  return response;
};

const getAttendanceById = async (id: number) => {
  const url = endpoints.BASE_API + "attendance/" + id;
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    }
  });
  return response;
};

const sendEmailBulk = async (params: any) => {
  const url = endpoints.BASE_API + "attendance/send";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const downloadReport = async (params: any) => {
  const url = endpoints.BASE_API + "attendance/download";
  const response = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
    responseType: "blob",
  });
  return response;
};

const getSpecificAttendance = async (params: any) => {
  const url = endpoints.BASE_API + "attendance/specific";
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });
  return response;
}

const downloadSalarySlip = async (params: any) => {
  const url = endpoints.BASE_API + "attendance/salary";
  axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer",
      params,
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const filename =`Salary ${params.name} ${params.month}${params.year}.xlsx`;
      fileDownload(blob, filename);
    });
};

const checkin = async (params: any) => {
  const url = endpoints.BASE_API + "attendance";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const sendEmail = async (params: any) => {
  const url = endpoints.BASE_API + "attendance/send-email";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const downloadPayroll = async (params: any) => {
  const url = endpoints.BASE_API + "attendance/payroll";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
  }).then((res) => {
    var blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fileDownload(blob, `payroll_${params.month}_${params.year}.xlsx`);
  });
};

const attendance = {
  getAttendance,
  downloadReport,
  getSpecificAttendance,
  downloadSalarySlip,
  checkin,
  sendEmail,
  sendEmailBulk,
  getAttendanceById,
  downloadPayroll,
  getDashboard
};

export default attendance;
