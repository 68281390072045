import { Box, Button, Stack, Typography } from "@mui/material";
import get from 'lodash/get';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import { useAppSelector } from "../hooks/useSelector";

export default function AccountProfilePage() {
    const { user } = useAppSelector((state) => state.global)

    return (
        <MainLayout>
            <Breadcrumb
                label="Account Profile"
                breadcrumbs={[
                    { label: 'Account Profile', href: '/app/profile' }
                ]}
            />

            <Box sx={{ mt: 2 }}>
                <Stack direction="row" spacing={1}>
                    <Button variant="outlined" component={RouterLink} to="/app/profile/change-password">
                        CHANGE PASSWORD
                    </Button>
                </Stack>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Stack>
                    <Typography variant="body1" color="grey.600" fontWeight="300">
                        Full Name
                    </Typography>
                    <Typography variant="body1" color="grey.600" fontWeight="700" sx={{ mt: 1 }}>
                        {get(user, 'name')}
                    </Typography>
                </Stack>
            </Box>
        </MainLayout>
    )
}