import { yupResolver } from "@hookform/resolvers/yup";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import CookieJs from "js-cookie";
import { get } from "lodash";
import Moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import ProtectedPermissionView from "../../components/ProtectedPermissionView";
import useNotification from "../../hooks/useNotification";
import branch from "../../services/branch";
import sales from "../../services/sales";
import queryDefault from "../../utils/queryDefault";

export default function InputSales() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") ?? "");
  const schema = yup.object().shape({
    branchid: yup.string().required("branch name is a required field"),
  });
  const [options] = useState<any>({
    keyword: "",
  });
  const [photoGantiKertas, setPhotoGantiKertas] = useState<File[]>([]);
  const [photoPrintCount, setPhotoPrintCount] = useState<File[]>([]);
  const [photoQrManual, setPhotoQrManual] = useState<File[]>([]);
  const [photoFraud, setPhotoFraud] = useState<File[]>([]);

  interface FormValues {
    branchid: Number;
    date: String;
    username: String;
    pic_pagi: String;
    pic_malam: String;
    beginning: Number;
    kertas_baru: Number;
    kertas_hilang_kebuang: Number;
    ending: Number;
    struk_cash: Number;
    struk_qr: Number;
    print_count: Number;
    total_kesalahan_fraud: Number;
    crew_teman: Number;
    free_voucher: Number;
    double_print: Number;
    print_error_test: Number;
    fraud: Number;
    lainnya: Number;
    keterangan: String;
    validasi_check: Number;
    kertas: File[];
    print: File[];
    qr: File[];
    file_fraud: File[];
  }

  const [value, setValue] = useState<Dayjs | null>(dayjs(new Date()));
  const {
    control,
    handleSubmit,
    setValue: setFormValue,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      branchid: 0,
      date: Moment(new Date()).format("YYYY-MM-DD"),
      username: user.username,
      pic_pagi: "",
      pic_malam: "",
      beginning: 0,
      kertas_baru: 0,
      kertas_hilang_kebuang: 0,
      ending: 0,
      struk_cash: 0,
      struk_qr: 0,
      print_count: 0,
      total_kesalahan_fraud: 0,
      crew_teman: 0,
      free_voucher: 0,
      double_print: 0,
      print_error_test: 0,
      fraud: 0,
      lainnya: 0,
      keterangan: "",
      validasi_check: 0,
      kertas: [],
      print: [],
      qr: [],
      file_fraud: [],
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (id) {
      sales.getSales(id).then(({ data }) => {
        setFormValue("branchid", data.branchid);
        setFormValue("pic_pagi", data.pic_pagi);
        setFormValue("pic_malam", data.pic_malam);
        setFormValue("beginning", data.beginning);
        setFormValue("kertas_baru", data.kertas_baru);
        setFormValue("kertas_hilang_kebuang", data.kertas_hilang_kebuang);
        setFormValue("ending", data.ending);
        setFormValue("struk_cash", data.struk_cash);
        setFormValue("struk_qr", data.struk_qr);
        setFormValue("print_count", data.print_count);
        setFormValue("total_kesalahan_fraud", data.total_kesalahan_fraud);
        setFormValue("crew_teman", data.crew_teman);
        setFormValue("free_voucher", data.free_voucher);
        setFormValue("double_print", data.double_print);
        setFormValue("print_error_test", data.print_error_test);
        setFormValue("fraud", data.fraud);
        setFormValue("lainnya", data.lainnya);
        setFormValue("keterangan", data.keterangan);
        setFormValue("validasi_check", data.validasi_check);
        setValue(dayjs(data.date))
      });
    }
  }, [id]);

  useEffect(() => {
    setFormValue(
      "date",
      Moment(value?.format("YYYY-MM-DD")).format("YYYY-MM-DD")
    );
  }, [value]);

  const getBranches = useQuery(
    ["branch", options],
    ({ queryKey }) => {
      return branch.getAllBranch({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const createSales = useMutation("create-report", sales.createSales, {
    onSuccess: (response) => {
      notification.onOpen({
        message:
          response.message === ""
            ? "Sales was successfully created!"
            : response.message,
        type: "success",
        position: "top",
      });
      navigate("/app/dashboard");
    },
    onError: (error: any) => {
      notification.onOpen({
        message:
          error?.response?.data?.message === undefined
            ? "Something went wrong"
            : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateSales = useMutation("update-sales", sales.updateSales, {
    onSuccess: (response) => {
      notification.onOpen({
        message:
          response.message === ""
            ? "Sales was successfully updated!"
            : response.message,
        type: "success",
        position: "top",
      });
      navigate("/app/sales");
    },
    onError: (error: any) => {
      notification.onOpen({
        message:
          error?.response?.data?.message === undefined
            ? "Something went wrong"
            : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setImages: React.Dispatch<React.SetStateAction<File[]>>,
    field: keyof FormValues
  ) => {
    if (event.target.files) {
      const fileList = Array.from(event.target.files);
      setImages((prevImages) => [...prevImages, ...fileList]);
      const currentValues = getValues(field) as File[];
      setFormValue(field, [...currentValues, ...fileList] as any);
    }
  };

  const handleDelete = (
    index: number,
    setImages: React.Dispatch<React.SetStateAction<File[]>>,
    images: File[],
    field: keyof FormValues
  ) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    setFormValue(field, updatedImages);
  };

  const onSubmit = async (data: any) => {
    if (id) {
      data.id = id;
      console.log(data)
      return updateSales.mutate(data)
    }
    if (
      photoFraud.length > 5 ||
      photoGantiKertas.length > 5 ||
      photoPrintCount.length > 5 ||
      photoQrManual.length > 5
    ) {
      notification.onOpen({
        message: "Cannot upload image more than 5 in one category",
        type: "error",
        position: "top",
      });
      return;
    }
    const formData = new FormData();
    photoGantiKertas.forEach((photo) => {
      formData.append("kertas", photo);
    });
    photoPrintCount.forEach((photo) => {
      formData.append("print", photo);
    });
    photoQrManual.forEach((photo) => {
      formData.append("qr", photo);
    });
    photoFraud.forEach((photo) => {
      formData.append("file_fraud", photo);
    });
    // Add other form data here
    Object.keys(data).forEach((key) => {
      if (!["kertas", "print", "qr", "file_fraud"].includes(key)) {
        formData.append(key, data[key]);
      }
    });

    createSales.mutate(formData);
  };

  return (
    <MainLayout>
      <Breadcrumb
        label={"Input Sales"}
        breadcrumbs={[
          { label: "Sales Dashboard", href: "/app/dashboard" },
          {
            label: "Input Sales",
            href: "/app/dashboard/input-sales",
          },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 8, 9]}>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={5} xs={11} mr={1}>
              <FormControl fullWidth margin="normal">
                <FormLabel required>Branch</FormLabel>
                <Controller
                  name="branchid"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Select
                      id="branchId"
                      value={getValues().branchid}
                      onChange={(event) => {
                        setFormValue("branchid", Number(event.target.value));
                      }}
                      defaultValue={getValues().branchid}
                      disabled={!!id}
                    >
                      <MenuItem key={0} value={0}>
                        Select Branch
                      </MenuItem>
                      {get(getBranches, "data.data", []).map(
                        (data: any, index: number) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>PIC Pagi</FormLabel>
                <Controller
                  name="pic_pagi"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={5} xs={11} mr={1}>
              <FormControl fullWidth margin="normal">
                <FormLabel required>Date</FormLabel>
                <Controller
                  name="date"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={value}
                        disabled={!!id}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(props) => <TextField {...props} />}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>PIC Malam</FormLabel>
                <Controller
                  name="pic_malam"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={5} xs={11}>
              <Grid container>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Beginning</FormLabel>
                    <Controller
                      name="beginning"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Tambah Kertas Baru</FormLabel>
                    <Controller
                      name="kertas_baru"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Kertas Hilang</FormLabel>
                    <Controller
                      name="kertas_hilang_kebuang"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Ending</FormLabel>
                    <Controller
                      name="ending"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={5} xs={11}>
              <Grid container>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Struk Cash</FormLabel>
                    <Controller
                      name="struk_cash"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Struk QR</FormLabel>
                    <Controller
                      name="struk_qr"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Print Count</FormLabel>
                    <Controller
                      name="print_count"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Total Fraud</FormLabel>
                    <Controller
                      name="total_kesalahan_fraud"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={5} xs={11}>
              <Grid container>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Crew Teman Photo</FormLabel>
                    <Controller
                      name="crew_teman"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Free Voucher</FormLabel>
                    <Controller
                      name="free_voucher"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Double Print</FormLabel>
                    <Controller
                      name="double_print"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Print Error / Test</FormLabel>
                    <Controller
                      name="print_error_test"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={5} xs={11}>
              <Grid container>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Kecurangan Customer</FormLabel>
                    <Controller
                      name="fraud"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={5} mr={1}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel>Lainnya</FormLabel>
                    <Controller
                      name="lainnya"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item sm={12} mr={1}>
                <ProtectedPermissionView acceptPermissions={[1, 8]}>
                  <FormControl margin="normal">
                    <FormLabel>Validasi</FormLabel>
                    <Controller
                      name="validasi_check"
                      control={control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>
                </ProtectedPermissionView>
              </Grid>
            </Grid>
            <Grid item sm={10} mr={1}>
              <FormControl fullWidth margin="normal">
                <FormLabel>Keterangan Lainnya</FormLabel>
                <Controller
                  name="keterangan"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          {!id && (
            <Grid container>
              <Grid item lg={5} sm={5} mr={1}>
                <FormControl fullWidth margin="normal">
                  <Controller
                    name="kertas"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          type="file"
                          name="kertas"
                          multiple
                          onChange={(event) =>
                            handleFileChange(
                              event,
                              setPhotoGantiKertas,
                              "kertas"
                            )
                          }
                          hidden
                        />
                        <Button
                          variant="contained"
                          component="span"
                          onClick={() => {
                            let element: HTMLElement = document.querySelector(
                              'input[name="kertas"]'
                            )!;
                            element.click();
                          }}
                        >
                          Upload Photo Ganti Kertas
                        </Button>
                      </>
                    )}
                  />
                  <List>
                    {photoGantiKertas.map((photo, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              handleDelete(
                                index,
                                setPhotoGantiKertas,
                                photoGantiKertas,
                                "kertas"
                              )
                            }
                          >
                            <Delete />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={photo.name} />
                      </ListItem>
                    ))}
                  </List>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <Controller
                    name="print"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          type="file"
                          name="print"
                          multiple
                          onChange={(event) =>
                            handleFileChange(event, setPhotoPrintCount, "print")
                          }
                          hidden
                        />
                        <Button
                          variant="contained"
                          component="span"
                          onClick={() => {
                            let element: HTMLElement = document.querySelector(
                              'input[name="print"]'
                            )!;
                            element.click();
                          }}
                        >
                          Upload Photo Print Count
                        </Button>
                      </>
                    )}
                  />
                  <List>
                    {photoPrintCount.map((photo, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              handleDelete(
                                index,
                                setPhotoPrintCount,
                                photoPrintCount,
                                "print"
                              )
                            }
                          >
                            <Delete />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={photo.name} />
                      </ListItem>
                    ))}
                  </List>
                </FormControl>
              </Grid>
              <Grid item lg={5} sm={5} mr={1}>
                <FormControl fullWidth margin="normal">
                  <Controller
                    name="qr"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          name="qr"
                          type="file"
                          multiple
                          onChange={(event) =>
                            handleFileChange(event, setPhotoQrManual, "qr")
                          }
                          hidden
                        />
                        <Button
                          variant="contained"
                          component="span"
                          onClick={() => {
                            let element: HTMLElement =
                              document.querySelector('input[name="qr"]')!;
                            element.click();
                          }}
                        >
                          Upload Photo QR Manual
                        </Button>
                      </>
                    )}
                  />
                  <List>
                    {photoQrManual.map((photo, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              handleDelete(
                                index,
                                setPhotoQrManual,
                                photoQrManual,
                                "qr"
                              )
                            }
                          >
                            <Delete />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={photo.name} />
                      </ListItem>
                    ))}
                  </List>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <Controller
                    name="file_fraud"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          name="file_fraud"
                          type="file"
                          multiple
                          onChange={(event) =>
                            handleFileChange(event, setPhotoFraud, "file_fraud")
                          }
                          hidden
                        />
                        <Button
                          variant="contained"
                          component="span"
                          onClick={() => {
                            let element: HTMLElement = document.querySelector(
                              'input[name="file_fraud"]'
                            )!;
                            element.click();
                          }}
                        >
                          Upload Photo Fraud
                        </Button>
                      </>
                    )}
                  />
                  <List>
                    {photoFraud.map((photo, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              handleDelete(
                                index,
                                setPhotoFraud,
                                photoFraud,
                                "fraud"
                              )
                            }
                          >
                            <Delete />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={photo.name} />
                      </ListItem>
                    ))}
                  </List>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              loading={createSales.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button
              variant="outlined"
              onClick={() => navigate("/app/dashboard")}
            >
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
