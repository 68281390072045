import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import CookieJs from "js-cookie";
import { get } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import role from "../../services/role";
import userService from "../../services/users";
import queryDefault from "../../utils/queryDefault";

export default function UserEdit() {
  const notification = useNotification();
  const { id } = useParams();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    name: yup
      .string()
      .min(5)
      .required("name is a required field and length > 5"),
    roleid: yup.string().required("role is a required field"),
    username: yup
      .string()
      .min(5)
      .matches(/^[a-z0-9]+$/, "lowercase, no symbol and space")
      .required("username is a required field and length > 5"),
  });
  const form = useForm({
    defaultValues: {
      id: "",
      name: "",
      username: "",
      roleid: "",
      admin_name: user.username,
    },
    resolver: yupResolver(schema),
  });

  const updateUser = useMutation("create_user", userService.editUser, {
    onSuccess: () => {
      navigate("/app/users/");

      notification.onOpen({
        message: "User was successfully updated!",
        type: "success",
        position: "top",
      });
    },
    onError: () => {
      notification.onOpen({
        message: "Failed Update User",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    updateUser.mutate({
      data: values,
    });
  };

  const listRole = useQuery("list-role", () =>
    role.getAllRole(1)
  );

  useEffect(() => {
    if(id) {
      userService.viewUser(id)
      .then(({data}) => {
        form.setValue("id", id);
        form.setValue("name", data.name);
        form.setValue("roleid", data.roleid);
        form.setValue("username", data.username);
      })
    }
  }, [id])
  

  return (
    <MainLayout>
      <Breadcrumb
        label="Edit User"
        breadcrumbs={[{ label: "Users", href: "/app/users" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1,2]}>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={4} xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Username</FormLabel>
                <Controller
                  name="username"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                      disabled={true}
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Name</FormLabel>
                <Controller
                  name="name"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Role</FormLabel>
                <Controller
                  name="roleid"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Box>
                      <Select
                        {...field}
                        size="small"
                        error={Boolean(fieldState.error?.message)}
                        fullWidth
                      >
                        {get(
                          listRole,
                          ["data", "data"],
                          []
                        ).map((item: any, i: any) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error={Boolean(fieldState.error?.message)}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={updateUser.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
