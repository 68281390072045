import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import CookieJs from "js-cookie";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import branch from "../../services/branch";
import group from "../../services/group";
import queryDefault from "../../utils/queryDefault";

export default function BranchEditPage() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    name: yup.string().required("branch name is a required field"),
  });
  const currentBranch = useQuery("view-branch", () => branch.getDetailBranch(id));
  const groups: any = useQuery("groups", () => group.getAllBranchGroup({ keyword: "" }));
  const form = useForm({
    defaultValues: {
      name: "",
      place: "",
      email: "",
      priceperunit: 0,
      issplitrevenue: false,
      splitrevenuepercentage: 0,
      isqrauto: false,
      mdr: 0,
      isppn: false,
      ppn: 0,
      username: user.username,
      minsplitrevenue: 0,
      reference_xendit: "",
      reference_midtrans: "",
      bank_rekening: "",
      no_rekening: "",
      atas_nama: "",
      npwp: "",
      group_id: 0
    },
    resolver: yupResolver(schema),
  });

  const createBranch = useMutation("create_branch", branch.createBranch, {
    onSuccess: (response) => {
      navigate("/app/branch/");

      notification.onOpen({
        message: response.message === "" ? "Branch was successfully created!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateBranch = useMutation("update-branch", branch.updateBranch, {
    onSuccess: (response) => {
      navigate("/app/branch/");

      notification.onOpen({
        message: response.message === "" ? "Branch was successfully updated!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    if (id) {
      updateBranch.mutate({
        data: {
          ...values,
          id: id
        }
      })
    } else {
      createBranch.mutate({
        data: values,
      });
    }
  };

  useEffect(() => {
    if (id) {
      form.setValue("name", get(currentBranch, "data.data.name"));
      form.setValue("place", get(currentBranch, "data.data.place"));
      form.setValue("email", get(currentBranch, "data.data.email"));
      form.setValue("priceperunit", get(currentBranch, "data.data.priceperunit"));
      form.setValue("issplitrevenue", get(currentBranch, "data.data.issplitrevenue"));
      form.setValue("splitrevenuepercentage", get(currentBranch, "data.data.splitrevenuepercentage"));
      form.setValue("isqrauto", get(currentBranch, "data.data.isqrauto"));
      form.setValue("mdr", get(currentBranch, "data.data.mdr"));
      form.setValue("isppn", get(currentBranch, "data.data.isppn"));
      form.setValue("ppn", get(currentBranch, "data.data.ppn"));
      form.setValue("minsplitrevenue", get(currentBranch, "data.data.minsplitrevenue"));
      form.setValue("reference_xendit", get(currentBranch, "data.data.reference_xendit"));
      form.setValue("reference_midtrans", get(currentBranch, "data.data.reference_midtrans"));
      form.setValue("bank_rekening", get(currentBranch, "data.data.bank_rekening"));
      form.setValue("no_rekening", get(currentBranch, "data.data.no_rekening"));
      form.setValue("atas_nama", get(currentBranch, "data.data.atas_nama"));
      form.setValue("npwp", get(currentBranch, "data.data.npwp"));
      form.setValue("group_id", get(currentBranch, "data.data.group_id"));
    }
  }, [currentBranch, form, id, groups]);

  return (
    <MainLayout>
      <Breadcrumb
        label={id ? "Edit Branch" : "Create Branch"}
        breadcrumbs={[
          { label: "Branch", href: "/app/branch" },
          {
            label: id ? "Edit Branch" : "Create Branch",
            href: "/app/branch/create-branch",
          },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        {!currentBranch.isLoading ? (
          <Box sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={4} xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel required>Branch Name</FormLabel>
                  <Controller
                    name="name"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Place</FormLabel>
                  <Controller
                    name="place"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Email</FormLabel>
                  <Controller
                    name="email"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Price Per Unit</FormLabel>
                  <Controller
                    name="priceperunit"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Split Revenue?</FormLabel>
                  <Controller
                    name="issplitrevenue"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <Select
                        id="issplitrevenue"
                        value={form.getValues().issplitrevenue ? 1 : 0}
                        onChange={(event) => {
                          form.setValue("issplitrevenue", Number(event.target.value) === 1);
                        }}
                        defaultValue={form.getValues().issplitrevenue ? 1 : 0}
                      >
                        <MenuItem value={1}>True</MenuItem>
                        <MenuItem value={0}>False</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Split Revenue %</FormLabel>
                  <Controller
                    name="splitrevenuepercentage"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          endAdornment: <InputAdornment position='end'>%</InputAdornment>
                        }}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Minimal Split Revenue</FormLabel>
                  <Controller
                    name="minsplitrevenue"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>QR Type</FormLabel>
                  <Controller
                    name="isqrauto"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <Select
                        id="isqrauto"
                        value={form.getValues().isqrauto ? 1 : 0}
                        onChange={(event) => {
                          form.setValue("isqrauto", Number(event.target.value) === 1);
                        }}
                        defaultValue={form.getValues().isqrauto ? 1 : 0}
                      >
                        <MenuItem value={1}>Auto</MenuItem>
                        <MenuItem value={0}>Manual</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>MDR %</FormLabel>
                  <Controller
                    name="mdr"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          endAdornment: <InputAdornment position='end'>%</InputAdornment>
                        }}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Use PPN?</FormLabel>
                  <Controller
                    name="isppn"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <Select
                        id="isppn"
                        value={form.getValues().isppn ? 1 : 0}
                        onChange={(event) => {
                          form.setValue("isppn", Number(event.target.value) === 1);
                        }}
                        defaultValue={form.getValues().isppn ? 1 : 0}
                      >
                        <MenuItem value={1}>True</MenuItem>
                        <MenuItem value={0}>False</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>PPN %</FormLabel>
                  <Controller
                    name="ppn"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          endAdornment: <InputAdornment position='end'>%</InputAdornment>
                        }}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Reference for Xendit Report</FormLabel>
                  <Controller
                    name="reference_xendit"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Reference for Midtrans Report</FormLabel>
                  <Controller
                    name="reference_midtrans"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Bank Rekening</FormLabel>
                  <Controller
                    name="bank_rekening"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>No Rekening</FormLabel>
                  <Controller
                    name="no_rekening"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Atas Nama</FormLabel>
                  <Controller
                    name="atas_nama"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>NPWP</FormLabel>
                  <Controller
                    name="npwp"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Group</FormLabel>
                  <Controller
                    name="group_id"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <Select
                        id="group_id"
                        value={form.getValues().group_id}
                        onChange={(event) => {
                          form.setValue("group_id", Number(event.target.value));
                        }}
                        defaultValue={form.getValues().group_id && form.getValues().group_id !== null ? form.getValues().group_id : 0}
                      >
                        <MenuItem value={0} key={0}>
                          Select Group
                        </MenuItem>
                        {get(
                          groups,
                          ["data", "data"],
                          []
                        ).map((item: any, i: any) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>) : <></>}
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createBranch.isLoading || updateBranch.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
