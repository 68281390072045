import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getAllBranchGroup = async (params: any) => {
  const url = endpoints.BRANCH_GROUP;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const getDetailBranchGroup = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.DETAIL_BRANCH_GROUP(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const createBranchGroup = async (params: any) => {
  const url = endpoints.BRANCH_GROUP;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteBranchGroup = async (id: number) => {
  const url = endpoints.DETAIL_BRANCH_GROUP(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateBranchGroup = async (params: any) => {
  const url = endpoints.DETAIL_BRANCH_GROUP(params?.data.id);
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const getAllEmployeeGroup = async (params: any) => {
  const url = endpoints.EMPLOYEE_GROUP;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const getDetailEmployeeGroup = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.DETAIL_EMPLOYEE_GROUP(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const createEmployeeGroup = async (params: any) => {
  const url = endpoints.EMPLOYEE_GROUP;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteEmployeeGroup = async (id: number) => {
  const url = endpoints.DETAIL_EMPLOYEE_GROUP(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateEmployeeGroup = async (params: any) => {
  const url = endpoints.DETAIL_EMPLOYEE_GROUP(params?.data.id);
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const services = {
  getAllBranchGroup,
  getDetailBranchGroup,
  createBranchGroup,
  deleteBranchGroup,
  updateBranchGroup,
  getAllEmployeeGroup,
  getDetailEmployeeGroup,
  createEmployeeGroup,
  deleteEmployeeGroup,
  updateEmployeeGroup,
};

export default services;
