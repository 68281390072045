import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from "js-cookie";
import get from "lodash/get";
import { default as moment, default as Moment } from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import AddTransactionForm from "../../components/AddTransactionForm";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import ProtectedPermissionView from "../../components/ProtectedPermissionView";
import useNotification from "../../hooks/useNotification";
import adjustmentService from "../../services/adjustment";
import attendanceService from "../../services/attendance";
import service from "../../services/employee";
import initial from "../../utils/initial";
import queryDefault from "../../utils/queryDefault";

function noRowsOverlay() {
  return (
    <Box sx={{ pt: 5 }}>
      <Typography variant="body1" textAlign="center" fontWeight="600">
        Sorry, data is not found
      </Typography>
    </Box>
  );
}

export default function EmployeeDetail() {
  const { id } = useParams();
  const user = JSON.parse(CookieJs.get("USER") ?? "");
  const notification = useNotification();
  const [pageSize, setPageSize] = useState<number>(5);
  const [selectedData, setSelectedData] = useState({
    id: 0,
    description: "",
    amount: "",
    category: "penalty",
    type: "",
    date: "",
  });
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [options, setOptions] = useState<any>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    employeeId: id,
    selectedDate: ""
  });
  const form = useForm({
    defaultValues: {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      employeeId: "",
    },
  });
  const [data, setData] = useState({
    id: "",
    name: "",
    username: "",
    roleid: "",
    admin_name: user.username,
    dailywages: 0,
    fine: 0,
    email: "",
    divisionid: 0,
    phone: "",
    ktp: "",
    ktp_img: initial.IMAGE_KTP,
    npwp: "",
    npwp_img: initial.IMAGE_NPWP,
    kk_img: initial.IMAGE_KK,
    alamat_ktp: "",
    alamat_domisili: "",
    tempat_lahir: "",
    tgl_lahir: Moment(new Date()).format('YYYY-MM-DD'),
    gender: "",
    nikah: "",
    agama: "",
    tgl_join: Moment(new Date()).format('YYYY-MM-DD'),
    divisi: "",
    bank_rekening: "",
    no_rekening: "",
    atas_nama: "",
    group_name: "",
    kontrak: "",
    divisionName: "",
    backupDivisionName: ""
  });

  const handleEditForm = (params: any) => {
    setSelectedData({
      id: params?.row?.id,
      description: params.row.description,
      amount: params.row.amount,
      category: params.row.is_penalty ? "penalty" : "bonus",
      type: params.row.type,
      date: params.row.date,
    });
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const adjustmentList = useQuery(
    ["adjustment", options],
    ({ queryKey }) => {
      return adjustmentService.getAllAdjustment({ ...queryKey[1] });
    },
    {
      ...queryDefault,
    }
  );

  const attendanceHistory = useQuery(
    ["attendance", options],
    ({ queryKey }) => {
      return attendanceService.getAttendance({ ...queryKey[1] });
    },
    {
      ...queryDefault,
    }
  );
  let thisYear = new Date().getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 5; x++) {
    allYears.push(thisYear - x);
  }

  const handleDeleteAdjustment = (id: number) => {
    adjustmentService
      .deleteAdjustment(id)
      .then(() => {
        notification.onOpen({
          message: "Success Delete Data",
          type: "success",
          position: "top",
        });
        adjustmentList.refetch();
      })
      .catch(() => {
        notification.onOpen({
          message: "Something Went Wrong",
          type: "error",
          position: "top",
        });
      });
  };

  const downloadSalary = useMutation(
    "download-salary",
    attendanceService.downloadSalarySlip,
    {
      ...queryDefault,
    }
  );

  const handleDownload = () => {
    downloadSalary.mutate({ ...options, name: data.name });
  };

  const emailSalary = useMutation(
    "email-salary",
    attendanceService.sendEmail,
    {
      ...queryDefault,
    }
  );

  const handleSendEmail = () => {
    emailSalary.mutate({ ...options, name: data.name });
    notification.onOpen({
      message: "Success Send Email",
      type: "success",
      position: "top",
    });
  };

  const handleSaveTransaction = (params: any) => {
    setSelectedData({
      id: 0,
      description: "",
      amount: "",
      category: "penalty",
      type: "",
      date: "",
    });
    params.is_penalty = params.category === "penalty" ? true : false;
    params.createdby = user.username;
    params.createtime = new Date();
    params.employeeid = id;
    if (params.id > 0) {
      adjustmentService
        .updateAdjustment(params)
        .then((data) => {
          notification.onOpen({
            message: "Success Update Data",
            type: "success",
            position: "top",
          });
          adjustmentList.refetch();
        })
        .catch((error) => {
          console.log(error);
          notification.onOpen({
            message: "Something Went Wrong",
            type: "error",
            position: "top",
          });
        });
      return;
    }
    adjustmentService
      .createAdjustment(params)
      .then((data) => {
        notification.onOpen({
          message: "Success Create Adjustment",
          type: "success",
          position: "top",
        });
        adjustmentList.refetch();
      })
      .catch((error) => {
        console.log(error);
        notification.onOpen({
          message: "Error Create Adjustment",
          type: "error",
          position: "top",
        });
      });
  };

  useEffect(() => {
    if (id) {
      service.viewEmployee(id).then(({ data }) => {
        setData({
          id: id,
          name: data.name,
          username: data.username,
          roleid: data.roleid,
          admin_name: user.username,
          dailywages: data.dailywages,
          fine: data.fine,
          email: data.email,
          divisionid: data.divisionid,
          phone: data.phone ? data.phone : "-",
          ktp: data.ktp ? data.ktp : "-",
          npwp: data.npwp ? data.npwp : "-",
          alamat_ktp: data.alamat_ktp ? data.alamat_ktp : "-",
          alamat_domisili: data.alamat_domisili ? data.alamat_domisili : "-",
          tempat_lahir: data.tempat_lahir ? data.tempat_lahir : "-",
          tgl_lahir: data.tgl_lahir ? data.tgl_lahir : "-",
          gender: data.gender ? data.gender : "-",
          nikah: data.nikah ? data.nikah : "-",
          agama: data.agama ? data.agama : "-",
          tgl_join: data.tgl_join ? data.tgl_join : "-",
          divisi: data.divisi ? data.divisi : "-",
          bank_rekening: data.bank_rekening ? data.bank_rekening : "-",
          no_rekening: data.no_rekening ? data.no_rekening : "-",
          atas_nama: data.atas_nama ? data.atas_nama : "-",
          group_name: data.group_name ? data.group_name : "-",
          ktp_img: !data.ktp_image || data.ktp_image === "" ? initial.IMAGE_KTP : "data:image/png;base64," + data.ktp_image,
          npwp_img: !data.npwp_image || data.npwp_image === "" ? initial.IMAGE_NPWP : "data:image/png;base64," + data.npwp_image,
          kk_img: !data.kk_image || data.kk_image === "" ? initial.IMAGE_KK : "data:image/png;base64," + data.kk_image,
          kontrak: data.kontrak,
          divisionName: data.division_name,
          backupDivisionName: data.backup_division_name ?? "-"
        });
      });
    }
  }, [id, user.username]);

  const uploadKTP = useMutation("upload_ktp", service.uploadKTP, {
    onSuccess: () => window.location.reload()
  });

  const uploadNPWP = useMutation("upload_npwp", service.uploadNPWP, {
    onSuccess: () => window.location.reload()
  });

  const uploadKK = useMutation("upload_kk", service.uploadKK, {
    onSuccess: () => window.location.reload()
  });

  const uploadKontrak = useMutation("upload_kontrak", service.uploadKontrak, {
    onSuccess: () => window.location.reload()
  });

  const handleUploadKTP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("id", data.id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadKTP.mutate(formData);
  };

  const handleUploadNPWP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("id", data.id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadNPWP.mutate(formData);
  };

  const handleUploadKK = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("id", data.id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadKK.mutate(formData);
  };

  const handleUploadKontrak = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("id", data.id);
    event.target.files && formData.append("file", event.target.files[0]);
    uploadKontrak.mutate(formData);
  };

  const navigate = useNavigate();
  const handleEdit = () => {
    navigate(`/app/employee/edit/${id}`);
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Employee Dashboard</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Employee Dashboard", href: "/app/attendance" }]}
        label={data.name}
        href={`/app/attendance/`}
      />

      <AddTransactionForm
        open={isFormOpen}
        onClose={handleCloseForm}
        onSave={handleSaveTransaction}
        initialData={selectedData}
      />

      <ProtectedPermissionPage acceptPermissions={[4, 5, 6]}>
        <Box sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={() => handleEdit()}>
            Edit
          </Button>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={{ lg: 3, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Name
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.name}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Email
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.email}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Tempat Lahir
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.tempat_lahir}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Tanggal Lahir
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {Moment(data.tgl_lahir).format('YYYY-MM-DD')}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Alamat Sesuai KTP
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.alamat_ktp}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  KTP
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.ktp}
                </Typography>
                <img src={data.ktp_img} alt="ktp" width="100%" />
                <LoadingButton variant="contained" component="label" sx={{ mt: 2 }} loading={uploadKTP.isLoading}>
                  Upload KTP <input onChange={handleUploadKTP} type="file" hidden />
                </LoadingButton>
              </Stack>
            </Grid>

            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Phone Number
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.phone}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Gender
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.gender}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Status Menikah
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.nikah}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Agama
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.agama}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Alamat Sesuai Domisili
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.alamat_domisili}
                </Typography>

                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    NPWP
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.npwp}
                  </Typography>
                  <img src={data.npwp_img} alt="npwp" width="100%" />
                  <LoadingButton variant="contained" component="label" sx={{ mt: 2 }} loading={uploadNPWP.isLoading}>
                    Upload NPWP <input onChange={handleUploadNPWP} type="file" hidden />
                  </LoadingButton>
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Tanggal Join Photomatics
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {Moment(data.tgl_join).format('YYYY-MM-DD')}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Rekening
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {`${data.bank_rekening} ${data.no_rekening} a/n ${data.atas_nama}`}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Group
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.group_name}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Jabatan
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.divisi}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Division
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.divisionName}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  BackupDivision
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.backupDivisionName}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 1 }}>
                {data.kontrak && (
                  <Button variant="contained" onClick={() => service.downloadKontrak({
                    id: data.id,
                    name: data.name
                  })}>Download Surat Kontrak</Button>
                )}
                <LoadingButton variant="contained" component="label" sx={{ mt: 1 }} loading={uploadKontrak.isLoading}>
                  Upload Surat Kontrak <input onChange={handleUploadKontrak} type="file" hidden />
                </LoadingButton>
              </Stack>

              <Stack sx={{ mt: 1 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Kartu Keluarga
                </Typography>
                <img src={data.kk_img} alt="kk" width="100%" />
                <LoadingButton variant="contained" component="label" sx={{ mt: 2 }} loading={uploadKK.isLoading}>
                  Upload Kartu Keluarga <input onChange={handleUploadKK} type="file" hidden />
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <FormControl margin="none">
              <FormLabel>Month</FormLabel>
              <Controller
                name="month"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="month"
                    value={form.getValues().month}
                    onChange={(event) => {
                      form.setValue("month", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        month: Number(event.target.value),
                      }));
                      attendanceHistory.refetch();
                      adjustmentList.refetch();
                    }}
                    defaultValue={form.getValues().month}
                  >
                    <MenuItem value={0}>Select Month</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Year</FormLabel>
              <Controller
                name="year"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="year"
                    value={form.getValues().year}
                    onChange={(event) => {
                      form.setValue("year", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        year: Number(event.target.value),
                      }));
                      attendanceHistory.refetch();
                      adjustmentList.refetch();
                    }}
                    defaultValue={form.getValues().year}
                  >
                    <MenuItem key={0} value={0}>
                      Select Year
                    </MenuItem>
                    {allYears.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Stack>

          <ProtectedPermissionView acceptPermissions={[4, 5]}>
            <Stack marginTop={2} marginBottom={2} direction="row" spacing={1}>
              <Button variant="contained" onClick={() => setIsFormOpen(true)}>
                Add Adjustment
              </Button>

              <LoadingButton
                variant="contained"
                onClick={() => handleDownload()}
                loading={downloadSalary.isLoading}
              >
                Download Payslip
              </LoadingButton>

              <LoadingButton
                variant="contained"
                onClick={() => handleSendEmail()}
                loading={emailSalary.isLoading}
              >
                Email Payslip
              </LoadingButton>
            </Stack>
          </ProtectedPermissionView>
        </Box>

        <ProtectedPermissionView acceptPermissions={[4, 5]}>
          <Typography variant="h1">Adjustment List</Typography>
          <Box sx={{ mt: 2 }}>
            <DataGrid
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              autoHeight
              loading={adjustmentList.isLoading}
              getRowId={(record) => get(record, "id")}
              rows={get(adjustmentList, "data.data", [])}
              paginationMode="client"
              components={{
                NoRowsOverlay: noRowsOverlay,
              }}
              disableSelectionOnClick
              rowsPerPageOptions={[5, 10, 15]}
              columns={[
                {
                  field: "date",
                  headerName: "Adjusted Date",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return moment(params.value).format("YYYY-MM-DD");
                  },
                },
                {
                  field: "createdtime",
                  headerName: "Created Time",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return moment(params.value).format("YYYY-MM-DD HH:mm:ss");
                  },
                },
                {
                  field: "createdby",
                  headerName: "Adjusted By",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                },
                {
                  field: "is_penalty",
                  headerName: "Category",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    if (!params.value) return "Bonus";
                    return "Penalty";
                  },
                },
                {
                  field: "type",
                  headerName: "Type",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return params.value;
                  },
                },
                {
                  field: "amount",
                  headerName: "Amount",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return params.value
                      ? "Rp " + params.value.toLocaleString("id-ID")
                      : "Rp 0";
                  },
                },
                {
                  field: "description",
                  headerName: "Description",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                },
                {
                  field: "actions",
                  headerName: "Action",
                  sortable: false,
                  filterable: false,
                  width: 200,
                  align: "center",
                  renderCell: (params) => {
                    return (
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => handleEditForm(params)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteAdjustment(params.row.id)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    );
                  },
                },
              ]}
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
            />
          </Box>
        </ProtectedPermissionView>

        <Typography variant="h1" marginTop="10px">
          Attendance History
        </Typography>

        <ProtectedPermissionView acceptPermissions={[4, 5, 6]}>
          <Box sx={{ mt: 2 }}>
            <DataGrid
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              autoHeight
              loading={attendanceHistory.isLoading}
              getRowId={(record) => get(record, "id")}
              rows={get(attendanceHistory, "data.data", [])}
              paginationMode="client"
              components={{
                NoRowsOverlay: noRowsOverlay,
              }}
              disableSelectionOnClick
              rowsPerPageOptions={[5, 10, 15]}
              columns={[
                {
                  field: "attendance_date",
                  headerName: "Date",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return moment(params.value).format("YYYY-MM-DD");
                  },
                },
                {
                  field: "day_open_time",
                  headerName: "Open Time",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return params.value
                  },
                },
                {
                  field: "checkin_time",
                  headerName: "CheckIn Time",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return moment(params.value).format("YYYY-MM-DD HH:mm:ss");
                  },
                },
                {
                  field: "checkout_time",
                  headerName: "CheckOut Time",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return moment(params.value).format("YYYY-MM-DD HH:mm:ss");
                  },
                },
                {
                  field: "checkin_photo",
                  headerName: "Checkin Photo",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => (
                    <a href={params.value} download={"CheckInPhoto"}>
                      <img
                        src={params.value}
                        alt="CheckIn"
                        width="200"
                        height="200"
                      />
                    </a>
                  ),
                },
                {
                  field: "checkout_photo",
                  headerName: "CheckOut Photo",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => (
                    <a href={params.value} download={"CheckOutPhoto"}>
                      <img
                        src={params.value}
                        alt="CheckOut"
                        width="200"
                        height="200"
                      />
                    </a>
                  ),
                },
                {
                  field: "working_hour",
                  headerName: "Working Hours",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                },
                {
                  field: "distance_checkin",
                  headerName: "Check In Distance",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return (
                      <>
                        <Typography
                          color={Number(params.value) > 25 ? "red" : "black"}
                        >
                          {params.value} m
                        </Typography>
                      </>
                    );
                  },
                },
                {
                  field: "distance_checkout",
                  headerName: "Check Out Distance",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 150,
                  renderCell: (params) => {
                    return (
                      <>
                        <Typography
                          color={Number(params.value) > 25 ? "red" : "black"}
                        >
                          {params.value} m
                        </Typography>
                      </>
                    );
                  },
                },
              ]}
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
            />
          </Box>
        </ProtectedPermissionView>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
