import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { get } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import branch from "../../services/branch";
import queryDefault from "../../utils/queryDefault";

export default function SplitRevenueEditPage() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      percentage: 0,
      month: 0,
      year: 0,
    }
  });

  const updateSplitRevenue = useMutation("updateSplitRevenue", branch.updateSplitRevenue, {
    onSuccess: (response) => {
      navigate("/app/branch/");

      notification.onOpen({
        message: response.message === "" ? "Split Revenue was successfully updated!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    updateSplitRevenue.mutate({
      data: {
        ...values,
        id: id
      }
    });
  };

  useEffect(() => {
    branch.getSpecificSplitRevenue({ id })
      .then((response) => {
        form.setValue("percentage", get(response, "data.percentage"));
        form.setValue("month", get(response, "data.month"));
        form.setValue("year", get(response, "data.year"));
      })
  }, [form, id]);

  let thisYear = new Date().getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 5; x++) {
    allYears.push(thisYear - x);
  }

  return (
    <MainLayout>
      <Breadcrumb
        label="Edit Split Revenue"
        breadcrumbs={[
          { label: "Branch", href: "/app/branch" },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={4} xs={12}>
              <FormControl fullWidth margin="normal">
                <FormLabel required>Percentage</FormLabel>
                <Controller
                  name="percentage"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>
                      }}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel required>Month</FormLabel>
                <Controller
                  name="month"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Select
                      id="month"
                      value={form.getValues().month}
                      onChange={(event) => {
                        form.setValue("month", Number(event.target.value));
                      }}
                      defaultValue={form.getValues().month}
                    >
                      <MenuItem value={0}>Select Month</MenuItem>
                      <MenuItem value={1}>January</MenuItem>
                      <MenuItem value={2}>February</MenuItem>
                      <MenuItem value={3}>March</MenuItem>
                      <MenuItem value={4}>April</MenuItem>
                      <MenuItem value={5}>May</MenuItem>
                      <MenuItem value={6}>June</MenuItem>
                      <MenuItem value={7}>July</MenuItem>
                      <MenuItem value={8}>August</MenuItem>
                      <MenuItem value={9}>September</MenuItem>
                      <MenuItem value={10}>October</MenuItem>
                      <MenuItem value={11}>November</MenuItem>
                      <MenuItem value={12}>December</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel required>Year</FormLabel>
                <Controller
                  name="year"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Select
                      id="year"
                      value={form.getValues().year}
                      onChange={(event) => {
                        form.setValue("year", Number(event.target.value));
                      }}
                      defaultValue={form.getValues().year}
                    >
                      <MenuItem key={0} value={0}>
                        Select Year
                      </MenuItem>
                      {allYears.map((x) => (
                        <MenuItem key={x} value={x}>
                          {x}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={updateSplitRevenue.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
