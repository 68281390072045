import CookieJs from "js-cookie";

interface Props {
  children: any;
  acceptPermissions: number[];
}

export default function ProtectedPermissionView(props: Props) {
  const { children, acceptPermissions } = props;
  const user = JSON.parse(CookieJs.get("USER") || "");

  const isHavePermission = acceptPermissions.indexOf(user.roleid) >= 0;
  if (!isHavePermission) {
    return null;
  }

  return children;
}
