import {
  Box,
  Button
} from "@mui/material";
import React, { useEffect, useState } from 'react';
import Webcam from "react-webcam";
import useNotification from "../hooks/useNotification";
import attendanceService from "../services/attendance";
import { useNavigate } from "react-router-dom";

const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user"
};

export default function WebcamCapture(props) {
  const notification = useNotification();
  const [params, setParams] = useState({
    userid: props.options.userid,
    distance: props.options.distance,
    currentPosition: props.currentPosition,
    image: ''
  });



  useEffect(() => {
    setParams({ ...params, distance: props.options.distance, currentPosition: props.currentPosition })
  }, [props]);
  
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setParams({...params, image: imageSrc});
  });
  const navigate = useNavigate();


  const handleCheckin = React.useCallback(() => {
    const formData = new FormData();
    formData.append("userid", params.userid);
    formData.append("distance", params.distance);
    formData.append("lat", params.currentPosition.latitude)
    formData.append("lng", params.currentPosition.longitude)
    const blob = dataURItoBlob(params.image);
    formData.append("file", blob, 'screenshot.png');
    attendanceService.checkin(formData).then((response) => {
      if (response.success) {
        navigate(`/app/attendance/dashboard`);
        notification.onOpen({
          message: response.message === "" ? "Branch was successfully created!" : response.message,
          type: "success",
          position: "top",
        });
        window.location.reload();
      }
      else {
        notification.onOpen({
          message: response.message === "" === undefined ? "Something went wrong" : response.message === "",
          type: "error",
          position: "top",
        });
      }
    }, (error) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    })
  });

  return (
    <div className="webcam-container">
      <div className="webcam-img">{params.image === ''?<Webcam
        audio={false}
        height={200}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={200}
        videoConstraints={videoConstraints}
        />:<img src={params.image}/>}
      </div>
      <div>{params.image !== ''?
        <Box sx={{ mt: 3 }}>
          <Button variant="outlined" onClick={(e)=>{
            e.preventDefault();
            setParams({...params, image: ''});}}>
            Retake Image
          </Button>
          <Button variant="outlined" onClick={(e)=>{
            e.preventDefault();
            handleCheckin();}}>
            Submit
          </Button>
        </Box>:
        <Button variant="outlined" onClick={(e)=>{
            e.preventDefault();
            capture();
          }}>
          Capture
        </Button>
      }  
      </div>
    </div>
  );
};