import axios from "axios";

import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";
import fileDownload from 'js-file-download';

const getEmployee = async (params: any) => {
  const url = endpoints.EMPLOYEE_URL;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const getEmployeeByUserId = async (viewId: any) => {
  const url = endpoints.VIEW_EMPLOYEE_BY_USER(viewId);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    }
  });

  return response;
};

const createEmployee = async (params: any) => {
  const url = endpoints.EMPLOYEE_URL;

  const { data: response } = await axios.post(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const viewEmployee = async (viewId: any) => {
  const url = endpoints.VIEW_EMPLOYEE(viewId);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editEmployee = async (params: any) => {
  const url = endpoints.VIEW_EMPLOYEE(params?.data.id);

  const { data: response } = await axios.patch(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteEmployee = async (params: any) => {
  const url = endpoints.VIEW_EMPLOYEE(params.id + '-' + params.admin_name);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadKTP = async (params: any) => {
  const url = endpoints.BASE_API + "employee/ktp";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadNPWP = async (params: any) => {
  const url = endpoints.BASE_API + "employee/npwp";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadKK = async (params: any) => {
  const url = endpoints.BASE_API + "employee/kk";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadKontrak = async (params: any) => {
  const url = endpoints.BASE_API + "employee/kontrak";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const downloadKontrak = async (params: any) => {
  const url = endpoints.BASE_API + "employee/kontrak/" + params.id;
  await axios.get(url, {
    headers: {
      Authorization: accessToken,
      ContentType: 'application/pdf',
    },
    responseType: 'arraybuffer',
  }).then((res) => {
    const contentDisposition = res.headers['content-disposition'];
    const filename = contentDisposition?.split('filename=')[1] || `kontrak-${params.name}.pdf`;
    var blob = new Blob([res.data], { type: 'application/pdf' });
    fileDownload(blob, filename);
  });
};

const uploadBulk = async (params: any) => {
  const url = endpoints.BASE_API + "employee/bulk";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadBulkAdjustment = async (params: any) => {
  const url = endpoints.BASE_API + "employee/bulk-adjustment";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const exportEmployee = async () => {
  const url = endpoints.BASE_API + "employee/bulk";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer",
  }).then((res) => {
    var blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fileDownload(blob, `employee_all.xlsx`);
  });
};

const getBulkAdjustmentTemplate = async () => {
  const url = endpoints.BASE_API + "employee/bulk-adjustment";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer",
  }).then((res) => {
    var blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fileDownload(blob, `adjustment_template.xlsx`);
  });
};

const employee = {
  getEmployee,
  createEmployee,
  editEmployee,
  viewEmployee,
  deleteEmployee,
  getEmployeeByUserId,
  uploadKTP,
  uploadNPWP,
  uploadKK,
  uploadKontrak,
  downloadKontrak,
  uploadBulk,
  uploadBulkAdjustment,
  exportEmployee,
  getBulkAdjustmentTemplate,
};

export default employee;
