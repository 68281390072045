import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import CookieJs from "js-cookie";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import Map from "../components/Map";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import SearchAutocomplete from "../components/SearchAutoComplete";
import useNotification from "../hooks/useNotification";
import branch from "../services/division";
import queryDefault from "../utils/queryDefault";

export default function DivisionEditPage() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") ?? "");
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLngLiteral>({
      lat: -6.1753917, // Default initial latitude
      lng: 106.8271533, // Default initial longitude
    });
  const schema = yup.object().shape({
    name: yup.string().required("division name is a required field"),
    address: yup.string().required("address is a required field"),
  });
  const currentBranch = useQuery("view-branch", () =>
    branch.getDetailDivision(id)
  );
  const form = useForm({
    defaultValues: {
      name: "",
      address: "",
      location: { x: 0, y: 0 },
      open_time: dayjs(new Date()),
      close_time: dayjs(new Date()),
      sunday_open_time: dayjs(new Date()),
      sunday_close_time: dayjs(new Date()),
      monday_open_time: dayjs(new Date()),
      monday_close_time: dayjs(new Date()),
      tuesday_open_time: dayjs(new Date()),
      tuesday_close_time: dayjs(new Date()),
      wednesday_open_time: dayjs(new Date()),
      wednesday_close_time: dayjs(new Date()),
      thursday_open_time: dayjs(new Date()),
      thursday_close_time: dayjs(new Date()),
      friday_open_time: dayjs(new Date()),
      friday_close_time: dayjs(new Date()),
      saturday_open_time: dayjs(new Date()),
      saturday_close_time: dayjs(new Date()),
      username: user.username,
    },
    resolver: yupResolver(schema),
  });

  const handleMarkerDragEnd = (lat: number, lng: number) => {
    setMarkerPosition({ lat, lng });
    form.setValue("location.x", lat);
    form.setValue("location.y", lng);
  };

  const handlePlaceSelected = (
    place: google.maps.places.PlaceResult | null
  ) => {
    if (place) {
      setMarkerPosition({
        lat: place.geometry?.location?.lat() ?? 0,
        lng: place.geometry?.location?.lng() ?? 0,
      });
      form.setValue("location", {
        x: place.geometry?.location?.lat() ?? 0,
        y: place.geometry?.location?.lng() ?? 0,
      });
      form.setValue("address", place?.name ?? form.getValues("address"));
    }
  };

  const createBranch = useMutation("create_branch", branch.createDivision, {
    onSuccess: (response) => {
      navigate("/app/division/");

      notification.onOpen({
        message:
          response.message === ""
            ? "Division was successfully created!"
            : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message:
          error?.response?.data?.message === undefined
            ? "Something went wrong"
            : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateBranch = useMutation("update-branch", branch.updateDivision, {
    onSuccess: (response) => {
      navigate("/app/division/");

      notification.onOpen({
        message:
          response.message === ""
            ? "Division was successfully updated!"
            : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message:
          error?.response?.data?.message === undefined
            ? "Something went wrong"
            : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    const open_time = `${form.getValues("open_time").hour()}:${form
      .getValues("open_time")
      .minute()}:00`;
    const close_time = `${form.getValues("close_time").hour()}:${form
      .getValues("close_time")
      .minute()}:00`;
    const sunday_open_time = `${form
      .getValues("sunday_open_time")
      .hour()}:${form.getValues("sunday_open_time").minute()}:00`;
    const sunday_close_time = `${form
      .getValues("sunday_close_time")
      .hour()}:${form.getValues("sunday_close_time").minute()}:00`;
    const monday_open_time = `${form
      .getValues("monday_open_time")
      .hour()}:${form.getValues("monday_open_time").minute()}:00`;
    const monday_close_time = `${form
      .getValues("monday_close_time")
      .hour()}:${form.getValues("monday_close_time").minute()}:00`;
    const tuesday_open_time = `${form
      .getValues("tuesday_open_time")
      .hour()}:${form.getValues("tuesday_open_time").minute()}:00`;
    const tuesday_close_time = `${form
      .getValues("tuesday_close_time")
      .hour()}:${form.getValues("tuesday_close_time").minute()}:00`;
    const wednesday_open_time = `${form
      .getValues("wednesday_open_time")
      .hour()}:${form.getValues("wednesday_open_time").minute()}:00`;
    const wednesday_close_time = `${form
      .getValues("wednesday_close_time")
      .hour()}:${form.getValues("wednesday_close_time").minute()}:00`;
    const thursday_open_time = `${form
      .getValues("thursday_open_time")
      .hour()}:${form.getValues("thursday_open_time").minute()}:00`;
    const thursday_close_time = `${form
      .getValues("thursday_close_time")
      .hour()}:${form.getValues("thursday_close_time").minute()}:00`;
    const friday_open_time = `${form
      .getValues("friday_open_time")
      .hour()}:${form.getValues("friday_open_time").minute()}:00`;
    const friday_close_time = `${form
      .getValues("friday_close_time")
      .hour()}:${form.getValues("friday_close_time").minute()}:00`;
    const saturday_open_time = `${form
      .getValues("saturday_open_time")
      .hour()}:${form.getValues("saturday_open_time").minute()}:00`;
    const saturday_close_time = `${form
      .getValues("saturday_close_time")
      .hour()}:${form.getValues("saturday_close_time").minute()}:00`;
    if (id) {
      updateBranch.mutate({
        data: {
          ...values,
          id: id,
          open_time: open_time,
          close_time: close_time,
          sunday_open_time,
          sunday_close_time,
          monday_open_time,
          monday_close_time,
          tuesday_open_time,
          tuesday_close_time,
          wednesday_close_time,
          wednesday_open_time,
          thursday_open_time,
          thursday_close_time,
          friday_open_time,
          friday_close_time,
          saturday_open_time,
          saturday_close_time,
        },
      });
    } else {
      createBranch.mutate({
        data: {
          ...values,
          open_time: open_time,
          close_time: close_time,
          sunday_open_time,
          sunday_close_time,
          monday_open_time,
          monday_close_time,
          tuesday_open_time,
          tuesday_close_time,
          wednesday_close_time,
          wednesday_open_time,
          thursday_open_time,
          thursday_close_time,
          friday_open_time,
          friday_close_time,
          saturday_open_time,
          saturday_close_time,
        },
      });
    }
  };

  const handleTimeChange = (newTime: any) => {
    form.setValue("open_time", newTime);
  };

  const handleTimeChangeClose = (newTime: any) => {
    form.setValue("close_time", newTime);
  };

  useEffect(() => {
    if (id) {
      form.setValue("name", get(currentBranch, "data.data.name"));
      form.setValue("location", get(currentBranch, "data.data.location"));
      form.setValue("address", get(currentBranch, "data.data.address"));
      form.setValue(
        "open_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.open_time"))
      );
      form.setValue(
        "close_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.close_time"))
      );
      form.setValue(
        "sunday_open_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.sunday_open_time"))
      );
      form.setValue(
        "sunday_close_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.sunday_close_time"))
      );
      form.setValue(
        "monday_open_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.monday_open_time"))
      );
      form.setValue(
        "monday_close_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.monday_close_time"))
      );
      form.setValue(
        "tuesday_open_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.tuesday_open_time"))
      );
      form.setValue(
        "tuesday_close_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.tuesday_close_time"))
      );
      form.setValue(
        "wednesday_open_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.wednesday_open_time"))
      );
      form.setValue(
        "wednesday_close_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.wednesday_close_time"))
      );
      form.setValue(
        "thursday_open_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.thursday_open_time"))
      );
      form.setValue(
        "thursday_close_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.thursday_close_time"))
      );
      form.setValue(
        "friday_open_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.friday_open_time"))
      );
      form.setValue(
        "friday_close_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.friday_close_time"))
      );
      form.setValue(
        "saturday_open_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.saturday_open_time"))
      );
      form.setValue(
        "saturday_close_time",
        dayjs("1900-01-01 " + get(currentBranch, "data.data.saturday_close_time"))
      );
    }
  }, [currentBranch.isFetched]);

  useEffect(() => {
    setMarkerPosition({
      lat: get(currentBranch, "data.data.location.x"),
      lng: get(currentBranch, "data.data.location.y"),
    });
  }, [id, currentBranch.isFetched]);
  return (
    <MainLayout>
      <Breadcrumb
        label={id ? "Edit Division" : "Create Division"}
        breadcrumbs={[
          { label: "Division", href: "/app/division" },
          {
            label: id ? "Edit Division" : "Create Division",
            href: "/app/division/create",
          },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[4, 5]}>
        {!currentBranch.isLoading ? (
          <Box sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={4} xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel required>Division Name</FormLabel>
                  <Controller
                    name="name"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Address</FormLabel>
                  <Controller
                    name="address"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Open Time</FormLabel>
                  <Controller
                    name="open_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("open_time")}
                          onChange={handleTimeChange}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Close Time</FormLabel>
                  <Controller
                    name="close_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("close_time")}
                          onChange={handleTimeChangeClose}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Sunday Open Time</FormLabel>
                  <Controller
                    name="sunday_open_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("sunday_open_time")}
                          onChange={(newTime) => form.setValue('sunday_open_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Sunday Close Time</FormLabel>
                  <Controller
                    name="sunday_close_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("sunday_close_time")}
                          onChange={(newTime) => form.setValue('sunday_close_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Monday Open Time</FormLabel>
                  <Controller
                    name="monday_open_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("monday_open_time")}
                          onChange={(newTime) => form.setValue('monday_open_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Monday Close Time</FormLabel>
                  <Controller
                    name="monday_close_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("monday_close_time")}
                          onChange={(newTime) => form.setValue('monday_close_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Tuesday Open Time</FormLabel>
                  <Controller
                    name="tuesday_open_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("tuesday_open_time")}
                          onChange={(newTime) => form.setValue('tuesday_open_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Tuesday Close Time</FormLabel>
                  <Controller
                    name="tuesday_close_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("tuesday_close_time")}
                          onChange={(newTime) => form.setValue('tuesday_close_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Wednesday Open Time</FormLabel>
                  <Controller
                    name="wednesday_open_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("wednesday_open_time")}
                          onChange={(newTime) => form.setValue('wednesday_open_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Wednesday Close Time</FormLabel>
                  <Controller
                    name="wednesday_close_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("wednesday_close_time")}
                          onChange={(newTime) => form.setValue('wednesday_close_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Thursday Open Time</FormLabel>
                  <Controller
                    name="thursday_open_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("thursday_open_time")}
                          onChange={(newTime) => form.setValue('thursday_open_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Thursday Close Time</FormLabel>
                  <Controller
                    name="thursday_close_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("thursday_close_time")}
                          onChange={(newTime) => form.setValue('thursday_close_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Friday Open Time</FormLabel>
                  <Controller
                    name="friday_open_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("friday_open_time")}
                          onChange={(newTime) => form.setValue('friday_open_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Friday Close Time</FormLabel>
                  <Controller
                    name="friday_close_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("friday_close_time")}
                          onChange={(newTime) => form.setValue('friday_close_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Saturday Open Time</FormLabel>
                  <Controller
                    name="saturday_open_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("saturday_open_time")}
                          onChange={(newTime) => form.setValue('saturday_open_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Saturday Close Time</FormLabel>
                  <Controller
                    name="saturday_close_time"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          ampm={false}
                          value={form.getValues("saturday_close_time")}
                          onChange={(newTime) => form.setValue('saturday_close_time', newTime || dayjs(new Date()))}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <SearchAutocomplete onPlaceSelected={handlePlaceSelected} />
                </FormControl>
                <Box sx={{
                  width: "100%",
                  height: "300px"
                }}>
                  <Map
                    center={markerPosition}
                    onMarkerDragEnd={handleMarkerDragEnd}
                    dragable={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createBranch.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
