import {
  Box
} from "@mui/material";

export default function ReportTable(props: any) {
  return (
    <Box sx={{ mt: 2 }}>
    <style>{`
            table, th, td {
              border: 1px solid black;
              font-size: 10px;
            }
            .greenBackground {
              background: #55eb44;
            }
            .redBackground {
              background: #eb4444;
            }
          `}</style>
          <table className="table">
            <thead>
              <tr>{props.head}</tr>
            </thead>
            <tbody>{props.body}</tbody>
          </table>
    </Box>
  )
}