import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import Maps from "./Map";

interface AttendanceCardProps {
  openTime: string;
  closeTime: string;
  checkInTime: string;
  checkOutTime: string;
  checkInPhotoUrl: string;
  checkOutPhotoUrl: string;
  checkInCoordinates: google.maps.LatLngLiteral | null;
  checkOutCoordinates: google.maps.LatLngLiteral | null;
  selectedDate: Date;
}

const AttendanceCard: React.FC<AttendanceCardProps> = ({
  checkInTime,
  checkOutTime,
  checkInPhotoUrl,
  checkOutPhotoUrl,
  checkInCoordinates,
  checkOutCoordinates,
  selectedDate,
  openTime,
  closeTime,
}) => {
  return (
    <Card
      sx={{
        margin: "10px 0",
        backgroundColor: "#f8f9fa",
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "top",
            marginTop: "10px",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Box marginRight="10px" textAlign="center">
            <Typography variant="body1">
              <strong>{selectedDate.getDate()}</strong>
            </Typography>
            <Typography variant="body1">
              <strong>
                {selectedDate.toLocaleDateString("en-US", { weekday: "short" })}
              </strong>
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <Typography variant="body1">
                <strong>Jam Masuk:</strong> {openTime}
              </Typography>
              <Typography variant="body1">
                <strong>Jam Keluar:</strong> {closeTime}
              </Typography>
            </Box>
            <hr />
            <Typography variant="h6" sx={{ marginTop: "10px" }}>
              <strong>Checkin:</strong> {checkInTime}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                flexDirection: { sm: "row" },
              }}
            >
              <Box
                component="img"
                src={checkInPhotoUrl}
                alt="User"
                sx={{
                  width: "150px",
                  height: "200px",
                  borderRadius: "10px",
                  marginRight: "10px",
                }}
              />
              <Box
                sx={{
                  flexGrow: 1,
                  marginLeft: { xs: "0", sm: "10px" },
                  width: "200px",
                  height: "200px",
                }}
              >
                {checkInCoordinates?.lat ? (
                  <Maps
                    center={checkInCoordinates}
                    dragable={false}
                    onMarkerDragEnd={() => console.log("")}
                  />
                ) : (
                  <Typography>No Checkin Data</Typography>
                )}
              </Box>
            </Box>

            <Typography variant="h6" sx={{ marginTop: "10px" }}>
              <strong>Checkout:</strong> {checkOutTime}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                flexDirection: { sm: "row" },
              }}
            >
              <Box
                component="img"
                src={checkOutPhotoUrl}
                alt="User"
                sx={{
                  width: "150px",
                  height: "200px",
                  borderRadius: "10px",
                  marginRight: "10px",
                }}
              />
              <Box
                sx={{
                  flexGrow: 1,
                  marginLeft: { xs: "0", sm: "10px" },
                  width: "200px",
                  height: "200px",
                }}
              >
                {checkOutCoordinates?.lat ? (
                  <Maps
                    center={checkOutCoordinates}
                    dragable={false}
                    onMarkerDragEnd={() => console.log("")}
                  />
                ) : (
                  <Typography>No Checkout Data</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AttendanceCard;
