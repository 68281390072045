import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, MenuItem } from '@mui/material';

interface Transaction {
  id?: number; // Change the type based on your identifier type
  description: string;
  amount: string;
  category: string;
  type: string;
  date: string; // New property for the transaction date
}

interface AddTransactionFormProps {
  open: boolean;
  onClose: () => void;
  onSave: (transaction: Transaction) => void;
  initialData?: Transaction;
}

const AddTransactionForm: React.FC<AddTransactionFormProps> = ({ open, onClose, onSave, initialData }) => {
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [category, setCategory] = useState('penalty');
  const [type, setType] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    // Set initial data when the form is opened for editing
    if (open && initialData) {
      setDescription(initialData.description || '');
      setAmount(initialData.amount || '');
      setCategory(initialData.category || 'penalty');
      setType(initialData.type || '');
      setDate(initialData.date || '');
    }
  }, [open, initialData]);

  const handleSave = () => {
    // Handle save logic here
    const editedTransaction: Transaction = {
      id: initialData?.id,
      description,
      amount,
      category,
      type,
      date,
    };

    onSave(editedTransaction);

    // Reset form fields
    setDescription('');
    setAmount('');
    setCategory('penalty');
    setType('');
    setDate('');

    // Close the form
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{initialData ? 'Edit' : 'Add'} Adjustment</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill out the form to {initialData ? 'edit' : 'add'} an adjustment.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          margin="dense"
          id="amount"
          label="Amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <TextField
          margin="dense"
          id="date"
          label="Date"
          type="date"
          fullWidth
          value={date}
          defaultValue={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <TextField
          select
          margin="dense"
          id="category"
          label="Category"
          fullWidth
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <MenuItem value="penalty">Penalty</MenuItem>
          <MenuItem value="bonus">Bonus</MenuItem>
        </TextField>
        {category === "penalty" ? (
          <TextField
            select
            margin="dense"
            id="type"
            label="Type"
            fullWidth
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value={4}>Template</MenuItem>
            <MenuItem value={5}>Form</MenuItem>
            <MenuItem value={6}>Kecurangan CS</MenuItem>
            <MenuItem value={7}>Lainnya</MenuItem>
            <MenuItem value={7}>Cuti</MenuItem>
          </TextField>
        ) : (
          <TextField
            select
            margin="dense"
            id="type"
            label="Type"
            fullWidth
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value={1}>Bensin</MenuItem>
            <MenuItem value={2}>Lembur</MenuItem>
            <MenuItem value={3}>Lainnya</MenuItem>
          </TextField>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>{initialData ? 'Save Changes' : 'Add Transaction'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTransactionForm;
