import CookieJs from "js-cookie";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import attendanceService from "../services/attendance";
import employeeService from "../services/employee";
import queryDefault from "../utils/queryDefault";

import moment from "moment";
import Logo from "../assets/images/2953962.jpg";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";

const AttendanceDashboard: React.FC = () => {
  const user = JSON.parse(CookieJs.get("USER") ?? "");
  let userId = user.id;

  const location = useLocation();
  const { state } = location;

  if (state) userId = state.id;
  const [options, setOptions] = useState<any>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    employeeId: 0,
    selectedDate: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    employeeService.getEmployeeByUserId(userId).then((response) => {
      setOptions({ ...options, employeeId: response.data.id });
    });
    if (state) userId = state.id;

  }, [user.id]);

  const attendanceHistory = useQuery(
    ["attendance", options],
    ({ queryKey }) => {
      return attendanceService.getAttendance({ ...queryKey[1] });
    },
    {
      ...queryDefault,
    }
  );

  const dashboardData = useQuery(
    ["dashboard", options],
    ({ queryKey }) => {
      return attendanceService.getDashboard({ ...queryKey[1] });
    },
    {
      ...queryDefault,
    }
  )
  const handleClick = (id: number) => {
    navigate(`/app/attendance/detail/${id}`);
  };
  return (
    <MainLayout>
      <Breadcrumb
        label="Attendance Dashboard"
        breadcrumbs={[{ label: "Attendance Dashboard" }]}
        prevUrl="/app/attendance"
      />
      <div className="attendance-page" style={{ marginTop: "10px" }}>
        <div className="summary-cards">
          <div className="card">
            <div className="circle blue">
              <span>{dashboardData.data?.data?.workingday}</span>
            </div>
            <p>Hari Kerja</p>
          </div>
          <div className="card">
            <div className="circle light-green">
              <span>{dashboardData.data?.data?.totalwork}</span>
            </div>
            <p>Masuk</p>
          </div>
          <div className="card">
            <div className="circle red">
              <span>{dashboardData.data?.data?.workingday - dashboardData.data?.data?.totalwork}</span>
            </div>
            <p>Absen</p>
          </div>
          <div className="card">
            <div className="circle yellow">
              <span>{dashboardData.data?.data?.totallate}</span>
            </div>
            <p>Telat</p>
          </div>
          <div className="card">
            <div className="circle purple">
              <span>{dashboardData.data?.data?.totalcuti}</span>
            </div>
            <p>Cuti</p>
          </div>
          <div className="card">
            <div className="circle light-green">
              <span>{dashboardData.data?.data?.totallembur}</span>
            </div>
            <p>Hari Lembur</p>
          </div>
        </div>

        <div className="filters">
          <select
            value={options.month}
            onChange={(e) => setOptions({ ...options, month: e.target.value })}
            className="filter-dropdown"
          >
            <option value="">Bulan</option>
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </select>
          <select
            value={options.year}
            onChange={(e) => setOptions({ ...options, year: e.target.value })}
            className="filter-dropdown"
          >
            <option value="">Tahun</option>
            <option value="2025">2025</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            {/* Add more years */}
          </select>
        </div>

        <div className="attendance-list">
          {attendanceHistory?.data?.data?.length > 0 ? (
            attendanceHistory?.data?.data.map((record: any, index: any) => (
              <div style={{ marginBottom: "10px" }}>
                <hr />
                <div
                  key={record.id}
                  className="attendance-record"
                  onClick={() => handleClick(record.id)}
                >
                  <p className="date">
                    {moment(record.attendance_date).format("YYYY-MM-DD")}
                  </p>
                  <div className="times">
                    <span>
                      <strong>Open Time : </strong>
                      {record.day_open_time}
                    </span>
                    <span>
                      <strong>Close Time : </strong>
                      {record.day_close_time}
                    </span>
                  </div>
                  <div className="times">
                    <span>
                      <strong>Checkin Time : </strong>
                      {moment(record.checkin_time).format("HH:mm:ss")} <strong>{record.is_late ? "(Late)" : ""}</strong>
                    </span>
                    <span
                      style={{ color: !record.is_checkout ? "red" : "black" }}
                    >
                      {" "}
                      <strong>Checkout Time : </strong>
                      {record.is_checkout
                        ? moment(record.checkout_time).format("HH:mm:ss")
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <img
              src={Logo}
              style={{
                maxWidth: '40%',
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AttendanceDashboard;
