import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useNotification from "../hooks/useNotification";
import attendanceService from "../services/attendance";
import employeeService from "../services/employee";
import group from "../services/group";
import queryDefault from "../utils/queryDefault";

interface Employee {
  id: number;
  name: string;
  dailywages: any;
  ismonthly: boolean;
  group_name: string;
}

interface AttendanceRecord {
  point: any;
  employeeId: number;
  day: number;
  hoursWorked: number;
}

const initialDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  0
);
const lastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
const last = lastMonth.getDate();
const lastMonthName = lastMonth.toLocaleString("default", { month: "short" });
const monthName = initialDate.toLocaleString("default", { month: "short" });

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

const AttendanceMonitor: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const notification = useNotification();
  const [options, setOptions] = useState<any>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    group_id: 0,
    employeeId: "",
  });
  const navigate = useNavigate();
  const initialColumn: GridColDef[] = [
    {
      field: "group_name",
      headerName: "Group",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>{params.value}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "name",
      headerName: "Employee Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack onClick={() => handleOnCellClick(params)}>
            <Typography color="blue">{params.value}</Typography>
          </Stack>
        );
      },
    },
    ...Array.from({ length: last - 25 }, (_, index) => ({
      field: `day${index + 26}`,
      headerName: `${lastMonthName} ${index + 26}`,
      type: "number",
      minWidth: 75,
      renderCell: (params: any) => {
        return (
          <Stack>
            <Typography color={params.value === 0 ? "red" : "black"}>
              {params.value}
            </Typography>
          </Stack>
        );
      },
    })),
    ...Array.from({ length: 25 }, (_, index) => ({
      field: `day${index + 1}`,
      headerName: `${monthName} ${index + 1}`,
      type: "number",
      minWidth: 75,
      renderCell: (params: any) => {
        return (
          <Stack>
            <Typography color={params.value === 0 ? "red" : "black"}>
              {params.value}
            </Typography>
          </Stack>
        );
      },
    })),
    {
      field: "totalPoint",
      headerName: "Total Points",
      type: "number",
      width: 140,
      renderCell: (params: any) => {
        return (
          <Stack>
            <Typography color={params.value === 0 ? "red" : "black"}>
              {params.value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "totalSalary",
      headerName: "Total Salary",
      type: "number",
      width: 120,
      renderCell: (params: any) => {
        return (
          <Stack>
            <Typography color={params.value === 0 ? "red" : "black"}>
              {params.value
                ? "Rp " + params.value.toLocaleString("id-ID")
                : "Rp 0"}
            </Typography>
          </Stack>
        );
      },
    },
  ];
  const [columns, setColumns] = useState(initialColumn);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [attendance, setAttendance] = useState<AttendanceRecord[]>([]);
  const form = useForm({
    defaultValues: {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      group_id: 0,
      employeeId: "",
    },
  });
  const handleOnCellClick = (params: GridCellParams) => {
    const { row } = params;
    navigate(`/app/attendance/${row.id}`, { state: row });
  };
  const handleRefreshColumns = (month: number, year: number) => {
    const monthBefore = month === 1 ? 12 : month - 1;
    const yearBefore = month === 1 ? year - 1 : year;

    const beforeDate = new Date(yearBefore, monthBefore, 0);
    const lastDate = beforeDate.getDate();
    const beforeMonthName = beforeDate.toLocaleString("default", {
      month: "short",
    });

    const newDate = new Date(year, month, 0);
    const monthName = newDate.toLocaleString("default", { month: "short" });

    const newColumns: GridColDef[] = [
      {
        field: "group_name",
        headerName: "Group",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Stack>
              <Typography>{params.value}</Typography>
            </Stack>
          );
        },
      },
      {
        field: "name",
        headerName: "Employee Name",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Stack onClick={() => handleOnCellClick(params)}>
              <Typography color="blue">{params.value}</Typography>
            </Stack>
          );
        },
      },
      {
        field: "isMonthly",
        headerName: "Is Monthly Wages",
        minWidth: 50,
      },
      {
        field: "dailyWages",
        headerName: "Wages",
        type: "number",
        width: 140,
        renderCell: (params: any) => {
          return (
            <Stack>
              <Typography>
                {params.value
                  ? "Rp " + params.value.toLocaleString("id-ID")
                  : "Rp 0"}
              </Typography>
            </Stack>
          );
        },
      },
      ...Array.from({ length: lastDate - 25 }, (_, index) => ({
        field: `day${index + 26}`,
        headerName: `${beforeMonthName} ${index + 26}`,
        minWidth: 75,
        renderCell: (params: any) => {
          return (
            <Stack>
              <Typography color={params.value === 'A' ? "red" : params.value === '-' ? 'black' : 'green'}>
                {params.value}
              </Typography>
            </Stack>
          );
        },
      })),
      ...Array.from({ length: 25 }, (_, index) => ({
        field: `day${index + 1}`,
        headerName: `${monthName} ${index + 1}`,
        minWidth: 75,
        renderCell: (params: any) => {
          return (
            <Stack>
              <Typography color={params.value === 'A' ? "red" : params.value === '-' ? 'black' : 'green'}>
                {params.value}
              </Typography>
            </Stack>
          );
        },
      })),
      {
        field: "totalIn",
        headerName: "Total In",
        type: "number",
        width: 80,
        renderCell: (params: any) => {
          return (
            <Stack>
              <Typography color="green">
                {params.value}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "totalLate",
        headerName: "Total Late",
        type: "number",
        width: 80,
        renderCell: (params: any) => {
          return (
            <Stack>
              <Typography color="black">
                {params.value}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "totalAbsent",
        headerName: "Total Absent",
        type: "number",
        width: 80,
        renderCell: (params: any) => {
          return (
            <Stack>
              <Typography color="red">
                {params.value}
              </Typography>
            </Stack>
          );
        },
      },
      // {
      //   field: "totalPoint",
      //   headerName: "Total Points",
      //   type: "number",
      //   width: 120,
      //   renderCell: (params: any) => {
      //     return (
      //       <Stack>
      //         <Typography color={params.value === 0 ? "red" : "black"}>
      //           {params.value}
      //         </Typography>
      //       </Stack>
      //     );
      //   },
      // },
      // {
      //   field: "totalSalary",
      //   headerName: "Total Salary",
      //   type: "number",
      //   width: 140,
      //   renderCell: (params: any) => {
      //     return (
      //       <Stack>
      //         <Typography color={params.value === 0 ? "red" : "black"}>
      //           {params.value
      //             ? "Rp " + params.value.toLocaleString("id-ID")
      //             : "Rp 0"}
      //         </Typography>
      //       </Stack>
      //     );
      //   },
      // },
    ];

    setColumns(newColumns);
  };
  const handleDownload = () => {
    setLoading(true);
    attendanceService
      .downloadReport(options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Trigger download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "attendance_report.xlsx");
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
      })
      .catch((error) => {
        notification.onOpen({
          message: "error download report",
          type: "error",
          position: "top",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    attendanceService.getAttendance(options).then((response) => {
      setAttendance(response.data);
    });

    employeeService.getEmployee({ keyword: "", group_id: options.group_id }).then((response) => {
      const data = response.data?.map((res: any) => {
        return {
          id: res.id,
          name: res.fullname,
          dailywages: res.dailywages,
          ismonthly: res.ismonthly,
          group_name: res.group_name
        };
      });
      setEmployees(data);
    });
    handleRefreshColumns(options.month, options.year);
  }, [options]);

  const getRows = (): any[] => {
    return employees.map((employee) => {
      const employeeAttendance = attendance.filter(
        (record) => record.employeeId === employee.id
      );

      const row: any = {
        id: employee.id,
        name: employee.name,
        isMonthly: employee.ismonthly,
        dailyWages: employee.dailywages,
        totalIn: 0,
        totalLate: 0,
        totalAbsent: 0,
        group_name: employee.group_name,
      };

      for (let i = 0; i < 31; i++) {
        const record = employeeAttendance.find((r) => r.day === i + 1);
        row[`day${i + 1}`] = record ? record.point : 'A';
        if (record?.point === 'F') {
          row.totalIn++
        } else if (record?.point === '-') {
          row.totalLate++
        } else row.totalAbsent++
      }
      return row;
    });
  };

  let thisYear = new Date().getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 5; x++) {
    allYears.push(thisYear - x);
  }

  const downloadPayroll = useMutation("dpayroll", attendanceService.downloadPayroll);
  const onHandlePayroll = (params: any) => downloadPayroll.mutate(params);

  const groups = useQuery(
    ["groups"],
    () => {
      return group.getAllEmployeeGroup({ keyword: "" });
    },
    {
      ...queryDefault,
    }
  );

  return (
    <MainLayout>
      <Helmet>
        <title>Attendance Monitoring</title>
      </Helmet>

      <Breadcrumb
        label="Attendance Monitoring"
        breadcrumbs={[{ label: "Attendance", href: "/app/attendance" }]}
      />
      <ProtectedPermissionPage acceptPermissions={[4, 5]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <FormControl>
              <FormLabel>Group</FormLabel>
              <Controller
                name="group_id"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="group_id"
                    value={form.getValues().group_id}
                    onChange={(event) => {
                      form.setValue("group_id", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        group_id: Number(event.target.value),
                      }));
                    }}
                    defaultValue={form.getValues().group_id ? form.getValues().group_id : 0}
                  >
                    <MenuItem key={0} value={0}>
                      Select Group
                    </MenuItem>
                    {get(groups, "data.data", []).map(
                      (data: any, index: number) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="none">
              <FormLabel>Month</FormLabel>
              <Controller
                name="month"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="month"
                    value={form.getValues().month}
                    onChange={(event) => {
                      form.setValue("month", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        month: Number(event.target.value),
                      }));
                    }}
                    defaultValue={form.getValues().month}
                  >
                    <MenuItem value={0}>Select Month</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Year</FormLabel>
              <Controller
                name="year"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="year"
                    value={form.getValues().year}
                    onChange={(event) => {
                      form.setValue("year", Number(event.target.value));
                      setOptions((prev: any) => ({
                        ...prev,
                        year: Number(event.target.value),
                      }));
                    }}
                    defaultValue={form.getValues().year}
                  >
                    <MenuItem key={0} value={0}>
                      Select Year
                    </MenuItem>
                    {allYears.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Stack>
          <Stack marginTop={2} direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={handleDownload}
              loading={loading}
            >
              Download Report
            </LoadingButton>

            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onHandlePayroll)}
              loading={downloadPayroll.isLoading}
            >
              Download Payroll
            </LoadingButton>
          </Stack>
        </Box>
        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            rows={getRows()}
            rowCount={getRows().length}
            pagination
            disableSelectionOnClick
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={columns}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
};

export default AttendanceMonitor;
