import { Box, Grid, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { get } from "lodash";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import service from "../../services/group";
import queryDefault from "../../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function EmployeeGroupDetail() {
  const location = useLocation();
  const path = location.pathname.split("/");
  const group_id = path.pop() || "";

  const currentGroup = useQuery(
    ["current", group_id],
    () => service.getDetailEmployeeGroup(group_id),
    {
      ...queryDefault,
    }
  );

  return (
    <MainLayout>
      <Helmet>
        <title>Group Detail</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Group", href: "/app/employee-group" }]}
        label={currentGroup.data?.data?.name}
        href={`/app/employee-group/`}
      />

      <ProtectedPermissionPage acceptPermissions={[4, 5]}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Name
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {currentGroup.data?.data?.name}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Typography sx={{
          fontWeight: 'bold',
          mt: 2
        }}>Employees</Typography>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={currentGroup.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(currentGroup, "data.employees", [])}
            rowCount={get(currentGroup, "data.employees", []).length}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}

            columns={[
              {
                field: "name",
                headerName: "Employee Name",
                flex: 1,
                filterable: false,
                minWidth: 150,
              }
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
