import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getAllRole = async (params: number) => {
  const url = endpoints.VIEW_LIST_ROLE;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: { type: params }
  });

  return response;
};

const role = {
  getAllRole,
};

export default role;
