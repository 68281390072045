// src/components/AbsenceDataGrid.tsx
import { CheckSharp } from "@mui/icons-material";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import sales from "../../services/sales";
import queryDefault from "../../utils/queryDefault";

// Define types for data
interface AbsenceData {
  id: number;
  date: string; // Format: 'YYYY-MM-DD'
  branch: string;
}

// Sample data - replace with actual data fetching logic
const sampleData: AbsenceData[] = [
  { id: 1, date: "2024-07-01", branch: "Branch A" },
  { id: 2, date: "2024-07-05", branch: "Branch A" },
  // Add more sample data as needed
];

const AbsenceDataGrid: React.FC = () => {
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  let thisYear = new Date().getFullYear();
  let allYears: number[] = [];
  for (let x = 0; x <= 5; x++) {
    allYears.push(thisYear - x);
  }

  // Generate columns for each day of the month
  const generateColumns = () => {
    const columns: GridColDef[] = [
      { field: "branch", headerName: "Branch", flex: 1, minWidth: 300 },
    ];

    const daysInMonth = new Date(year, month, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
      columns.push({
        field: `day-${day}`,
        headerName: `${day}`,
        width: 50,
        renderCell: (params) =>
          params.row[`day-${day}`] ? <CheckSharp color="primary" /> : "❌",
      });
    }

    return columns;
  };

  const options: any = {
    month,
    year,
  };
  const listAttendance = useQuery(
    ["userlist", options],
    ({ queryKey }) => {
      return sales.getAttendees({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  // Prepare rows
  const prepareRows = () => {
    const rows: any[] = [];
    listAttendance?.data?.data?.forEach(
      (item: { date: string; branch: string }) => {
        const date = new Date(item.date);
        const rowDate =
          date.getMonth() + 1 === month && date.getFullYear() === year
            ? date.getDate()
            : null;

        if (rowDate) {
          let row = rows.find((r) => r.branch === item.branch);
          if (!row) {
            row = { id: rows.length + 1, branch: item.branch };
            rows.push(row);
          }
          row[`day-${rowDate}`] = true;
        }
      }
    );

    return rows;
  };

  const columns = generateColumns();
  let rows = prepareRows();

  return (
    <MainLayout>
      <Helmet>
        <title>Attendance Monitoring</title>
      </Helmet>

      <Breadcrumb
        label="Attendance Monitoring"
        breadcrumbs={[{ label: "Attendance", href: "/app/attendance" }]}
      />
      <ProtectedPermissionPage acceptPermissions={[1, 8, 9]}>
        <Box my={2}>
          <Stack direction="row" spacing={1}>
            <FormControl>
              <FormLabel id="month-label">Month</FormLabel>
              <Select
                labelId="month-label"
                value={month}
                onChange={(e) => setMonth(e.target.value as number)}
              >
                <MenuItem value={0}>Select Month</MenuItem>
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel id="year-label">Year</FormLabel>
              <Select
                labelId="year-label"
                value={year}
                onChange={(e) => setYear(e.target.value as number)}
              >
                <MenuItem key={0} value={0}>
                  Select Year
                </MenuItem>
                {allYears.map((x) => (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>

        <Box sx={{ mb: 3 }}>
        <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/dashboard/input-sales"
            >
              ADD SALES
            </Button>
        </Stack>
      </Box>

        <DataGrid
          loading={listAttendance.isLoading}
          autoHeight
          rows={rows}
          columns={columns}
          pageSize={100}
        />
      </ProtectedPermissionPage>
    </MainLayout>
  );
};

export default AbsenceDataGrid;
