import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import CookieJs from "js-cookie";
import get from "lodash/get";
import { default as Moment, default as moment } from 'moment';
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import branch from "../../services/branch";
import group from "../../services/group";
import service from "../../services/sales";
import queryDefault from "../../utils/queryDefault";
import useNotification from "../../hooks/useNotification";
import useAlert from "../../hooks/useAlert";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function ListSales() {
  const [view, setView] = useState({
    crew: true,
    hq: false,
    selisih: false,
    summary: false
  });
  const [valueFrom, setValueFrom] = useState<Dayjs | null>(dayjs(new Date()));
  const [valueTo, setValueTo] = useState<Dayjs | null>(dayjs(new Date()));
  const defaultValues = {
    branch_id: 0,
    group_id: 0,
    date_from: Moment(new Date()).format('YYYY-MM-DD'),
    date_to: Moment(new Date()).format('YYYY-MM-DD'),
  };
  const salesCache = CookieJs.get("SALES_PAGE") === undefined ? defaultValues : JSON.parse(CookieJs.get("SALES_PAGE") || "");
  useEffect(() => {
    if (salesCache !== undefined) {
      setValueFrom(dayjs(new Date(salesCache.date_from)));
      setValueTo(dayjs(new Date(salesCache.date_to)));
    }
  }, []);
  const form = useForm({ defaultValues: salesCache });

  const [options, setOptions] = useState<any>(salesCache);
  const listSales = useQuery(
    ["listSales", options],
    ({ queryKey }) => {
      return service.listSales({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const getBranches = useQuery(
    ["branch"],
    () => {
      return branch.getAllBranch({ keyword: "" });
    },
    {
      ...queryDefault,
    }
  );

  const groups = useQuery(
    ["groups"],
    () => {
      return group.getAllBranchGroup({ keyword: "" });
    },
    {
      ...queryDefault,
    }
  );

  useEffect(() => {
    form.setValue("date_from", Moment(valueFrom?.format('YYYY-MM-DD')).format('YYYY-MM-DD'));
    CookieJs.set(
      "SALES_PAGE",
      JSON.stringify({
        branch_id: form.getValues().branch_id,
        group_id: form.getValues().group_id,
        date_from: form.getValues().date_from,
        date_to: form.getValues().date_to,
      })
    );
    setOptions({ branch_id: options.branch_id, group_id: options.group_id, date_from: form.getValues().date_from, date_to: form.getValues().date_to });
  }, [valueFrom, form]);

  useEffect(() => {
    form.setValue("date_to", Moment(valueTo?.format('YYYY-MM-DD')).format('YYYY-MM-DD'));
    CookieJs.set(
      "SALES_PAGE",
      JSON.stringify({
        branch_id: form.getValues().branch_id,
        group_id: form.getValues().group_id,
        date_from: form.getValues().date_from,
        date_to: form.getValues().date_to,
      })
    );
    setOptions({ branch_id: options.branch_id, group_id: options.group_id, date_from: form.getValues().date_from, date_to: form.getValues().date_to });
  }, [valueTo, form]);


  const notification = useNotification();
  const alert = useAlert();
  const deleteSales = useMutation("delete-sales", service.deleteSales, {
    onSuccess: () => {
      listSales.refetch();
      notification.onOpen({
        message: "Branch was successfully deleted!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onDelete = (id: number) => {
    alert.onClose();
    deleteSales.mutate(id);
  };
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete sales report?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  return (
    <MainLayout>
      <Breadcrumb
        label={"Sales Report"}
        breadcrumbs={[
          { label: "Sales", href: "/app/sales" },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 8]}>
        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1}>
            <FormControl margin="normal">
              <FormLabel>Branch</FormLabel>
              <Controller
                name="branch_id"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="branchid"
                    value={form.getValues().branch_id}
                    onChange={(event) => {
                      form.setValue("branch_id", Number(event.target.value));
                      CookieJs.set(
                        "SALES_PAGE",
                        JSON.stringify({
                          branch_id: Number(event.target.value),
                          group_id: form.getValues().group_id,
                          date_from: form.getValues().date_from,
                          date_to: form.getValues().date_to,
                        })
                      );
                      setOptions((prev: any) => ({
                        ...prev,
                        branch_id: Number(event.target.value)
                      }));
                      listSales.refetch();
                    }}
                    defaultValue={form.getValues().branch_id ? form.getValues().branch_id : 0}
                  >
                    <MenuItem key={0} value={0}>
                      Select Branch
                    </MenuItem>
                    {get(getBranches, "data.data", []).map(
                      (data: any, index: number) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Group</FormLabel>
              <Controller
                name="group_id"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    id="branchid"
                    value={form.getValues().group_id}
                    onChange={(event) => {
                      form.setValue("group_id", Number(event.target.value));
                      CookieJs.set(
                        "SALES_PAGE",
                        JSON.stringify({
                          branch_id: form.getValues().branch_id,
                          group_id: Number(event.target.value),
                          date_from: form.getValues().date_from,
                          date_to: form.getValues().date_to,
                        })
                      );
                      setOptions((prev: any) => ({
                        ...prev,
                        group_id: Number(event.target.value)
                      }));
                      listSales.refetch();
                    }}
                    defaultValue={form.getValues().group_id ? form.getValues().group_id : 0}
                  >
                    <MenuItem key={0} value={0}>
                      Select Group
                    </MenuItem>
                    {get(groups, "data.data", []).map(
                      (data: any, index: number) => (
                        <MenuItem key={index} value={data.id}>
                          {data.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Date From</FormLabel>
              <Controller
                name="date_from"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      views={["day", "month", "year"]}
                      value={valueFrom}
                      onChange={newValue => {
                        setValueFrom(newValue);
                      }}
                      renderInput={props => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>

            <FormControl margin="normal">
              <FormLabel>Date To</FormLabel>
              <Controller
                name="date_to"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      views={["day", "month", "year"]}
                      value={valueTo}
                      onChange={newValue => {
                        setValueTo(newValue);
                      }}
                      renderInput={props => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                )}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={3} xs={3}>
              <Button variant={view.crew ? "contained" : "outlined"} sx={{ width: "90%" }} onClick={() => setView({
                crew: true,
                hq: false,
                selisih: false,
                summary: false
              })}>Data Kru Cabang</Button>
            </Grid>
            <Grid item lg={3} xs={3}>
              <Button variant={view.hq ? "contained" : "outlined"} sx={{ width: "90%" }} onClick={() => setView({
                crew: false,
                hq: true,
                selisih: false,
                summary: false
              })}>HQ Checking</Button>
            </Grid>
            <Grid item lg={3} xs={3}>
              <Button variant={view.selisih ? "contained" : "outlined"} sx={{ width: "90%" }} onClick={() => setView({
                crew: false,
                hq: false,
                selisih: true,
                summary: false
              })}>Selisih Checking Cabang</Button>
            </Grid>
            <Grid item lg={3} xs={3}>
              <Button variant={view.summary ? "contained" : "outlined"} sx={{ width: "90%" }} onClick={() => setView({
                crew: false,
                hq: false,
                selisih: false,
                summary: true
              })}>Summary</Button>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              component={RouterLink}
              to={`/app/sales/monthly`}
            >
              Monthly Summary
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          {view.crew && (
            <DataGrid
              autoHeight
              loading={listSales.isLoading}
              getRowId={(record) => get(record, "id")}
              rows={get(listSales, "data.data", [])}
              rowCount={get(listSales, "data.data", []).length}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: CustomNoRowOverlay,
              }}
              columns={[
                {
                  field: "branch_name",
                  headerName: "Cabang",
                  flex: 1,
                  filterable: false,
                  minWidth: 300,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "date",
                  headerName: "Tanggal",
                  flex: 1,
                  filterable: false,
                  minWidth: 120,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{moment(params.value).format("DD/MM/YYYY")}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "pic_pagi",
                  headerName: "PIC Pagi",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "pic_malam",
                  headerName: "PIC Malam",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "kertas_baru",
                  headerName: "Kertas Baru",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "kertas_hilang_kebuang",
                  headerName: "Kertas Kebuang",
                  flex: 1,
                  filterable: false,
                  minWidth: 130,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "beginning",
                  headerName: "Beginning",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "ending",
                  headerName: "Ending",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "total",
                  headerName: "Total",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "struk_cash",
                  headerName: "Struk Cash",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "struk_qr",
                  headerName: "Struk QR",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "print_count",
                  headerName: "Print Count",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "perbedaan",
                  headerName: "Perbedaan",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography color={params.value !== 0 ? "red" : "black"}>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "photo_printcount",
                  headerName: "Photo Print Count",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 500,
                  renderCell: (params) =>
                    params.value !== "" ? params.value.split("|").map((val: string, idx: number) => (
                      <Button variant="contained" sx={{ mx: 1 }}>
                        <a
                          href={process.env.REACT_APP_BACKEND_URL + val}
                          download={`printCount-${val.split("/")[val.split("/").length - 1]}`}
                          target="_blank">
                          {`Photo${idx + 1}`}
                        </a>
                      </Button>
                    )) : "-"
                },
                {
                  field: "photo_qr_manual",
                  headerName: "Photo QR Manual",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 500,
                  renderCell: (params) =>
                    params.value !== "" ? params.value.split("|").map((val: string, idx: number) => (
                      <Button variant="contained" sx={{ mx: 1 }}>
                        <a
                          href={process.env.REACT_APP_BACKEND_URL + val}
                          download={`qrManual-${val.split("/")[val.split("/").length - 1]}`}
                          target="_blank">
                          {`Photo${idx + 1}`}
                        </a>
                      </Button>
                    )) : "-"
                },
                {
                  field: "photo_ganti_kertas",
                  headerName: "Photo Ganti Kertas",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 500,
                  renderCell: (params) =>
                    params.value !== "" ? params.value.split("|").map((val: string, idx: number) => (
                      <Button variant="contained" sx={{ mx: 1 }}>
                        <a
                          href={process.env.REACT_APP_BACKEND_URL + val}
                          download={`gantiKertas-${val.split("/")[val.split("/").length - 1]}`}
                          target="_blank">
                          {`Photo${idx + 1}`}
                        </a>
                      </Button>
                    )) : "-"
                },
                {
                  field: "actions",
                  headerName: "Action",
                  sortable: false,
                  filterable: false,
                  width: 200,
                  renderCell: (params) => {
                    return (
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          color="primary"
                          component={RouterLink}
                          to={`/app/dashboard/input-sales/${params.row.id}`}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => onClickDelete(params.row.id)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    );
                  },
                },
              ]}
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
            />
          )}
          {view.hq && (
            <DataGrid
              autoHeight
              loading={listSales.isLoading}
              getRowId={(record) => get(record, "id")}
              rows={get(listSales, "data.data", [])}
              rowCount={get(listSales, "data.data", []).length}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: CustomNoRowOverlay,
              }}
              columns={[
                {
                  field: "branch_name",
                  headerName: "Cabang",
                  flex: 1,
                  filterable: false,
                  minWidth: 300,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "date",
                  headerName: "Tanggal",
                  flex: 1,
                  filterable: false,
                  minWidth: 120,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{moment(params.value).format("DD/MM/YYYY")}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "total",
                  headerName: "Total Kertas Kru",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "total_transaksi_dashboard",
                  headerName: "Total Transaksi Dashboard",
                  flex: 1,
                  filterable: false,
                  minWidth: 220,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography color="blue">{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "selisih_transaksi",
                  headerName: "Selisih Transaksi",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography color={params.value !== 0 ? "red" : "black"}>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "actions",
                  headerName: "Action",
                  sortable: false,
                  filterable: false,
                  width: 200,
                  renderCell: (params) => {
                    return (
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          color="primary"
                          component={RouterLink}
                          to={`/app/dashboard/input-sales/${params.row.id}`}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => onClickDelete(params.row.id)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    );
                  },
                },
              ]}
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
            />
          )}
          {view.selisih && (
            <DataGrid
              autoHeight
              loading={listSales.isLoading}
              getRowId={(record) => get(record, "id")}
              rows={get(listSales, "data.data", [])}
              rowCount={get(listSales, "data.data", []).length}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: CustomNoRowOverlay,
              }}
              columns={[
                {
                  field: "branch_name",
                  headerName: "Cabang",
                  flex: 1,
                  filterable: false,
                  minWidth: 300,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "date",
                  headerName: "Tanggal",
                  flex: 1,
                  filterable: false,
                  minWidth: 120,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{moment(params.value).format("DD/MM/YYYY")}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "total_kesalahan_fraud",
                  headerName: "Total Kesalahan / Fraud Selisih",
                  flex: 1,
                  filterable: false,
                  minWidth: 250,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography color={params.value !== 0 ? "red" : "black"}>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "crew_teman",
                  headerName: "Crew / Teman Foto",
                  flex: 1,
                  filterable: false,
                  minWidth: 150,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "free_voucher",
                  headerName: "Free Voucher",
                  flex: 1,
                  filterable: false,
                  minWidth: 120,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "double_print",
                  headerName: "Double Print",
                  flex: 1,
                  filterable: false,
                  minWidth: 120,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "print_error_test",
                  headerName: "Print Error / Test Print",
                  flex: 1,
                  filterable: false,
                  minWidth: 180,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "fraud",
                  headerName: "Kecurangan Customer",
                  flex: 1,
                  filterable: false,
                  minWidth: 180,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "lainnya",
                  headerName: "Lainnya",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "keterangan",
                  headerName: "Detail Penjelasan Perbedaan",
                  flex: 1,
                  filterable: false,
                  minWidth: 1000,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value === "" ? "-" : params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "sisa_unknown",
                  headerName: "Sisa Unknown",
                  flex: 1,
                  filterable: false,
                  minWidth: 120,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography color={params.value !== 0 ? "red" : "black"}>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "photo_fraud",
                  headerName: "Photo Fraud",
                  flex: 1,
                  sortable: true,
                  filterable: true,
                  minWidth: 500,
                  renderCell: (params) =>
                    params.value !== "" ? params.value.split("|").map((val: string, idx: number) => (
                      <Button variant="contained" sx={{ mx: 1 }}>
                        <a
                          href={process.env.REACT_APP_BACKEND_URL + val}
                          download={`fraud-${val.split("/")[val.split("/").length - 1]}`}
                          target="_blank">
                          {`Photo${idx + 1}`}
                        </a>
                      </Button>
                    )) : "-"
                },
                {
                  field: "actions",
                  headerName: "Action",
                  sortable: false,
                  filterable: false,
                  width: 200,
                  renderCell: (params) => {
                    return (
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          color="primary"
                          component={RouterLink}
                          to={`/app/dashboard/input-sales/${params.row.id}`}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => onClickDelete(params.row.id)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    );
                  },
                },
              ]}
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
            />
          )}
          {view.summary && (
            <DataGrid
              autoHeight
              loading={listSales.isLoading}
              getRowId={(record) => get(record, "id")}
              rows={get(listSales, "data.data", [])}
              rowCount={get(listSales, "data.data", []).length}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: CustomNoRowOverlay,
              }}
              columns={[
                {
                  field: "branch_name",
                  headerName: "Cabang",
                  flex: 1,
                  filterable: false,
                  minWidth: 300,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "date",
                  headerName: "Tanggal",
                  flex: 1,
                  filterable: false,
                  minWidth: 120,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography>{moment(params.value).format("DD/MM/YYYY")}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "validasi_check",
                  headerName: "Validasi Checking",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography color="blue">{params.value !== 0 ? params.value : "-"}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "selisih_after_checking",
                  headerName: "Selisih After Checking",
                  flex: 1,
                  filterable: false,
                  minWidth: 100,
                  renderCell: (params) => {
                    return (
                      <Stack>
                        <Typography color={params.value !== 0 ? "red" : "black"}>{params.value}</Typography>
                      </Stack>
                    );
                  },
                },
                {
                  field: "actions",
                  headerName: "Action",
                  sortable: false,
                  filterable: false,
                  width: 200,
                  renderCell: (params) => {
                    return (
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          color="primary"
                          component={RouterLink}
                          to={`/app/dashboard/input-sales/${params.row.id}`}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => onClickDelete(params.row.id)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    );
                  },
                },
              ]}
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
            />
          )}
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
