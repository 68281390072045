import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { TextField } from '@mui/material';

interface SearchAutocompleteProps {
  onPlaceSelected: (place: google.maps.places.PlaceResult | null) => void;
}

const SearchAutocomplete: React.FC<SearchAutocompleteProps> = ({ onPlaceSelected }) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      onPlaceSelected(place);
    }
  };

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <TextField fullWidth margin='normal'
        type="text"
        placeholder="Search for a place"
        size="small"
      />
    </Autocomplete>
  );
};

export default SearchAutocomplete;
