import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import CookieJs from "js-cookie";
import { get } from "lodash";
import Moment from 'moment';
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import division from "../../services/division";
import service from "../../services/employee";
import group from "../../services/group";
import role from "../../services/role";
import queryDefault from "../../utils/queryDefault";

export default function EmployeeCreate() {
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    name: yup
      .string()
      .min(5)
      .required("name is a required field and length > 5"),
    roleid: yup.string().required("role is a required field"),
    username: yup
      .string()
      .min(5)
      .matches(/^[a-z0-9]+$/, "lowercase, no symbol and space")
      .required("username is a required field and length > 5"),
  });

  const [valueLahir, setValueLahir] = useState<Dayjs | null>(dayjs(new Date()));
  const [valueJoin, setValueJoin] = useState<Dayjs | null>(dayjs(new Date()));

  const form = useForm({
    defaultValues: {
      name: "",
      username: "",
      roleid: "",
      admin_name: user.username,
      dailywages: 0,
      email: "",
      divisionid: 0,
      backup_division: 0,
      ismonthly: false,
      phone: "",
      ktp: "",
      npwp: "",
      alamat_ktp: "",
      alamat_domisili: "",
      tempat_lahir: "",
      tgl_lahir: Moment(new Date()).format('YYYY-MM-DD'),
      gender: "",
      nikah: "",
      agama: "",
      tgl_join: Moment(new Date()).format('YYYY-MM-DD'),
      divisi: "",
      bank_rekening: "",
      no_rekening: "",
      atas_nama: "",
      group_id: 0,
    },
    resolver: yupResolver(schema),
  });

  const createEmployee = useMutation("create_employee", service.createEmployee, {
    onSuccess: () => {
      navigate("/app/employee/");

      notification.onOpen({
        message: "Employee was successfully created!",
        type: "success",
        position: "top",
      });
    },
    onError: () => {
      notification.onOpen({
        message: "Something went wrong",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    createEmployee.mutate({
      data: values,
    });
  };

  const listRole = useQuery("list-role", () => role.getAllRole(2));
  const listDivision = useQuery("list-division", () => division.getAllDivision(""));
  const groups: any = useQuery("groups", () => group.getAllEmployeeGroup({ keyword: "" }));

  return (
    <MainLayout>
      <Breadcrumb
        label="Create Employee"
        breadcrumbs={[{ label: "Employee", href: "/app/employee" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[4, 5]}>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={4} xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Username</FormLabel>
                <Controller
                  name="username"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Name</FormLabel>
                <Controller
                  name="name"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Role</FormLabel>
                <Controller
                  name="roleid"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Box>
                      <Select
                        {...field}
                        size="small"
                        error={Boolean(fieldState.error?.message)}
                        fullWidth
                      >
                        {get(listRole, ["data", "data"], []).map(
                          (item: any, i: any) => (
                            <MenuItem value={item?.id} key={item?.id}>
                              {item?.name}
                            </MenuItem>
                          )
                        )}
                      </Select>

                      <FormHelperText error={Boolean(fieldState.error?.message)}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel>Wage Type</FormLabel>
                <Controller
                  name="ismonthly"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Select
                      id="ismonthly"
                      value={form.getValues().ismonthly ? 1 : 0}
                      onChange={(event) => {
                        form.setValue("ismonthly", Number(event.target.value) === 1);
                      }}
                      defaultValue={form.getValues().ismonthly ? 1 : 0}
                    >
                      <MenuItem value={0}>Daily</MenuItem>
                      <MenuItem value={1}>Monthly</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Wages</FormLabel>
                <Controller
                  name="dailywages"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Email</FormLabel>
                <Controller
                  name="email"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Division</FormLabel>
                <Controller
                  name="divisionid"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Box>
                      <Select
                        {...field}
                        size="small"
                        error={Boolean(fieldState.error?.message)}
                        fullWidth
                      >
                        <MenuItem key={0} value={0}>
                          Select Division
                        </MenuItem>
                        {get(listDivision, ["data", "data"], []).map(
                          (item: any, i: any) => (
                            <MenuItem value={item?.id} key={item?.id}>
                              {item?.name}
                            </MenuItem>
                          )
                        )}
                      </Select>

                      <FormHelperText error={Boolean(fieldState.error?.message)}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Backup Division</FormLabel>
                <Controller
                  name="backup_division"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Box>
                      <Select
                        {...field}
                        size="small"
                        error={Boolean(fieldState.error?.message)}
                        fullWidth
                      >
                        <MenuItem key={0} value={0}>
                          Select Division
                        </MenuItem>
                        {get(listDivision, ["data", "data"], []).map(
                          (item: any, i: any) => (
                            <MenuItem value={item?.id} key={item?.id}>
                              {item?.name}
                            </MenuItem>
                          )
                        )}
                      </Select>

                      <FormHelperText error={Boolean(fieldState.error?.message)}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Phone Number</FormLabel>
                <Controller
                  name="phone"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>KTP</FormLabel>
                <Controller
                  name="ktp"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>NPWP</FormLabel>
                <Controller
                  name="npwp"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Alamat Sesuai KTP</FormLabel>
                <Controller
                  name="alamat_ktp"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Alamat Sesuai Domisili</FormLabel>
                <Controller
                  name="alamat_domisili"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Tempat Lahir</FormLabel>
                <Controller
                  name="tempat_lahir"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel>Tanggal Lahir</FormLabel>
                <Controller
                  name="tgl_lahir"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        views={["day", "month", "year"]}
                        value={valueLahir}
                        onChange={newValue => {
                          setValueLahir(newValue);
                        }}
                        renderInput={props => <TextField {...props} />}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Gender</FormLabel>
                <Controller
                  name="gender"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Status Menikah</FormLabel>
                <Controller
                  name="nikah"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Agama</FormLabel>
                <Controller
                  name="agama"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel>Tanggal Join Photomatis</FormLabel>
                <Controller
                  name="tgl_join"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        views={["day", "month", "year"]}
                        value={valueJoin}
                        onChange={newValue => {
                          setValueJoin(newValue);
                        }}
                        renderInput={props => <TextField {...props} />}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Jabatan</FormLabel>
                <Controller
                  name="divisi"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Bank Rekening</FormLabel>
                <Controller
                  name="bank_rekening"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>No Rekening</FormLabel>
                <Controller
                  name="no_rekening"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel>Atas Nama Rekening</FormLabel>
                <Controller
                  name="atas_nama"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel>Group</FormLabel>
                <Controller
                  name="group_id"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Select
                      id="group_id"
                      value={form.getValues().group_id}
                      onChange={(event) => {
                        form.setValue("group_id", Number(event.target.value));
                      }}
                      defaultValue={form.getValues().group_id && form.getValues().group_id !== null ? form.getValues().group_id : 0}
                    >
                      <MenuItem value={0} key={0}>
                        Select Group
                      </MenuItem>
                      {get(
                        groups,
                        ["data", "data"],
                        []
                      ).map((item: any, i: any) => (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createEmployee.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
