import {
  ExpandMoreOutlined, Menu as MenuIcon, NotificationsOutlined
} from "@mui/icons-material";
import {
  Avatar, Backdrop, Box, ButtonBase, Divider, IconButton, Menu,
  MenuItem, Stack,
  Theme, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";


// assets
import get from "lodash/get";
import Logo from "../../assets/images/logo_photomatics.png";
import useAlert from "../../hooks/useAlert";
import { useAppDispatch } from "../../hooks/useDispatch";
import { useAppSelector } from "../../hooks/useSelector";
import auth from "../../services/auth";
import { actions } from "../../store/reducers/global";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: "100%",
    backgroundColor: "#c9ffe5",
    borderBottom: "1px solid #d3c9e0",
    position: "fixed",
    zIndex: 99,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 768,
    },
  },
}));

const ProfileMenu = () => {
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const { user } = useAppSelector((state) => state.global);

  const [achorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    alert.onClose();
    window.location.href = "/";
    dispatch(auth.signOut());
  };

  const onSignOut = () => {
    handleClose();
    alert.onOpen({
      title: "Confirm Sign Out",
      message: "Are you sure want to sign out?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Sign out",
          onClick: signOut,
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  return (
    <Box>
      <ButtonBase onClick={handleClick}>
        <Stack direction="row" alignItems="center">
          <Avatar
            sx={{ width: 32, height: 32 }}
            src={get(user, "profile_picture", null)}
          >
            {get(user, "username")?.charAt(0)}
          </Avatar>

          <Box sx={{ mx: 1 }}>
            <Typography variant="body1" fontWeight="700" textAlign="right">
              {get(user, "username", "") || ""}
            </Typography>
          </Box>

          <ExpandMoreOutlined />
        </Stack>
      </ButtonBase>

      <Backdrop
        open={Boolean(achorEl)}
        sx={{ zIndex: 97 }}
        component="div"
        onClick={handleClose}
      >
        <Menu
          anchorEl={achorEl}
          open={Boolean(achorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            component={RouterLink}
            to="/app/profile"
            onClick={() => {
              handleClose();
              dispatch(actions.closeMenu());
            }}
          >
            <Typography variant="body1" fontWeight="600" color="grey.600">
              Profile
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={onSignOut}>
            <Typography variant="body1" fontWeight="600" color="grey.600">
              Sign Out
            </Typography>
          </MenuItem>
        </Menu>
      </Backdrop>
    </Box>
  );
};

export default function Header() {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  return (
    <Box className={classes.header} sx={{ px: 2, py: 1 }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            onClick={() => dispatch(actions.openSidebar())}
            sx={{ display: { lg: "none", md: "block" } }}
          >
            <MenuIcon />
          </IconButton>
          <RouterLink to="/app">
            <img src={Logo} style={{ height: 48 }} />
          </RouterLink>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton size="small" component={RouterLink} to="/app/suspicious">
            <NotificationsOutlined />
          </IconButton>
          <ProfileMenu />
        </Stack>
      </Stack>
    </Box>
  );
}
