import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getAllBranch = async (params: any) => {
  const url = endpoints.BRANCH;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const getDetailBranch = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.DETAIL_BRANCH(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const getBranchStats = async (params: any) => {
  const url = endpoints.BRANCH + "/stats";

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const createBranch = async (params: any) => {
  const url = endpoints.BRANCH;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteBranch = async (id: number) => {
  const url = endpoints.DETAIL_BRANCH(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateBranch = async (params: any) => {
  const url = endpoints.DETAIL_BRANCH(params?.data.id);
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const getAllSplitRevenue = async (params: any) => {
  const url = endpoints.BRANCH + '/splitrevenue/' + params.branch_id;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });
  return response;
}

const getSpecificSplitRevenue = async (params: any) => {
  const url = endpoints.BRANCH + '/splitrevenue-specific/' + params.id;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });
  return response;
}

const updateSplitRevenue = async (params: any) => {
  const url = endpoints.BRANCH + '/splitrevenue/' + params.data.id;
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const deleteSplitRevenue = async (id: number) => {
  const url = endpoints.BRANCH + '/splitrevenue/' + id;

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const uploadNPWP = async (params: any) => {
  const url = endpoints.BRANCH + '/npwp';
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getAllSplitRevenue,
  getSpecificSplitRevenue,
  updateSplitRevenue,
  getAllBranch,
  getDetailBranch,
  getBranchStats,
  createBranch,
  deleteBranch,
  updateBranch,
  deleteSplitRevenue,
  uploadNPWP
};

export default service;
