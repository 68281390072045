import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface WeekCalendarProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
}

const WeekCalendar: React.FC<WeekCalendarProps> = ({ selectedDate, onDateChange }) => {
  const getStartOfWeek = (date: Date) => {
    const start = new Date(date);
    start.setDate(date.getDate() - date.getDay());
    return start;
  };

  const getEndOfWeek = (date: Date) => {
    const end = new Date(date);
    end.setDate(date.getDate() + (6 - date.getDay()));
    return end;
  };

  const startOfWeek = getStartOfWeek(selectedDate);
  const endOfWeek = getEndOfWeek(selectedDate);

  const getWeekDates = () => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const current = new Date(startOfWeek);
      current.setDate(startOfWeek.getDate() + i);
      dates.push(current);
    }
    return dates;
  };

  const handlePrevWeek = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() - 7);
    onDateChange(newDate);
  };

  const handleNextWeek = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + 7);
    onDateChange(newDate);
  };

  const weekDates = getWeekDates();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton onClick={handlePrevWeek}>
        <ArrowBackIosIcon />
      </IconButton>
      <Box display="flex" justifyContent="space-around" width="100%" sx={{ backgroundColor: '#fff', borderRadius: '10px', padding: '5px' }}>
        {weekDates.map((date, index) => (
          <Box
            key={index}
            textAlign="center"
            onClick={() => onDateChange(date)}
            sx={{
              cursor: 'pointer',
              padding: '5px',
              backgroundColor: date.toDateString() === selectedDate.toDateString() ? '#1976d2' : 'transparent',
              borderRadius: '10px',
              color: date.toDateString() === selectedDate.toDateString() ? '#fff' : 'inherit',
              transition: 'background-color 0.3s, color 0.3s',
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {date.toLocaleDateString('en-US', { weekday: 'short' })}
            </Typography>
            <Typography variant="body1">
              {date.getDate()}
            </Typography>
          </Box>
        ))}
      </Box>
      <IconButton onClick={handleNextWeek}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default WeekCalendar;
