import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import WeekCalendar from "../components/WeekCalendar";
import AttendanceCard from "../components/AttendanceCard";
import { useParams } from "react-router-dom";
import attendanceService from "../services/attendance";
import moment from "moment";
import MainLayout from "../components/MainLayout";
import Breadcrumb from "../components/Breadcrumb";

interface AttendanceData {
  checkInTime: string;
  checkOutTime: string;
  checkInPhotoUrl: string;
  checkOutPhotoUrl: string;
  openTime: string;
  closeTime: string;
  checkInCoordinates: { lat: number; lng: number } | null;
  checkOutCoordinates: { lat: number; lng: number } | null;
  employeeId: number;
}

const AttendancePage: React.FC = () => {
  const { id } = useParams();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [attendanceData, setAttendanceData] = useState<AttendanceData>({
    checkInTime: "",
    checkOutTime: "",
    checkInPhotoUrl: "",
    checkOutPhotoUrl: "",
    checkInCoordinates: null,
    checkOutCoordinates: null,
    openTime: "",
    closeTime: "",
    employeeId: 0,
  });

  const onChangeDate = (date: Date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    attendanceService
      .getAttendance({
        employeeId: attendanceData.employeeId,
        selectedDate: moment(selectedDate).format("YYYY-MM-DD"),
      })
      .then((response) => {
        if (response.data.length > 0) {
          const {
            data: [data],
          } = response;
          setAttendanceData({
            checkInTime: moment(data.checkin_time).format("HH:mm:ss"),
            checkOutTime: moment(data.checkout_time).format("HH:mm:ss"),
            checkInPhotoUrl: data.checkin_photo,
            checkOutPhotoUrl: data.checkout_photo,
            checkInCoordinates: {
              lat: data.checkin_location?.x,
              lng: data.checkin_location?.y,
            },
            checkOutCoordinates: {
              lat: data.checkout_location?.x,
              lng: data.checkout_location?.y,
            },
            openTime: data.day_open_time,
            closeTime: data.day_close_time,
            employeeId: data.employeeid,
          });
        } else {
          setAttendanceData({
            ...attendanceData,
            checkInTime: "",
            checkOutTime: "",
            checkInPhotoUrl: "",
            checkOutPhotoUrl: "",
            checkInCoordinates: null,
            checkOutCoordinates: null,
            openTime: "",
            closeTime: "",
          });
        }
      });
  }, [selectedDate]);

  useEffect(() => {
    attendanceService.getAttendanceById(Number(id)).then((response) => {
      const { data } = response;
      setAttendanceData({
        checkInTime: moment(data.checkin_time).format("HH:mm:ss"),
        checkOutTime: moment(data.checkout_time).format("HH:mm:ss"),
        checkInPhotoUrl: data.checkin_photo,
        checkOutPhotoUrl: data.checkout_photo,
        checkInCoordinates: {
          lat: data?.checkin_location?.x,
          lng: data?.checkin_location?.y,
        },
        checkOutCoordinates: {
          lat: data?.checkout_location?.x,
          lng: data?.checkout_location?.y,
        },
        openTime: data.day_open_time,
        closeTime: data.day_close_time,
        employeeId: data.employeeid,
      });
      setSelectedDate(new Date(data.attendance_date))
    });
  }, [id]);

  return (
    <MainLayout>
      <Breadcrumb
        label="Attendance Detail"
        breadcrumbs={[{ label: "Attendance Detail" }]}
        prevUrl="/app/dashboard"
      />
      <Container sx={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <WeekCalendar
              selectedDate={selectedDate!}
              onDateChange={onChangeDate}
            />
          </Grid>
          <Grid item xs={12}>
            {!(
              attendanceData.checkInTime === "" &&
              attendanceData.checkOutTime === ""
            ) ? (
              <AttendanceCard
                selectedDate={selectedDate}
                checkInTime={attendanceData.checkInTime}
                checkOutTime={attendanceData.checkOutTime}
                checkInPhotoUrl={attendanceData.checkInPhotoUrl}
                checkOutPhotoUrl={attendanceData.checkOutPhotoUrl}
                checkInCoordinates={attendanceData.checkInCoordinates}
                checkOutCoordinates={attendanceData.checkOutCoordinates}
                openTime={attendanceData.openTime}
                closeTime={attendanceData.closeTime}
              />
            ) : (
              <Typography variant="body1">
                No attendance data available for this date.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </MainLayout>
  );
};

export default AttendancePage;
