import React from "react";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  Libraries
} from "@react-google-maps/api";

interface MapProps {
  center: google.maps.LatLngLiteral;
  onMarkerDragEnd: (lat: number, lng: number) => void;
  dragable: boolean;
}

const libraries:Libraries = ["places"]
const Maps: React.FC<MapProps> = ({ center, onMarkerDragEnd, dragable }) => {
  const { isLoaded } = useJsApiLoader({
    id: "map",
    googleMapsApiKey: process.env.REACT_APP_LIBRARIES_GOOGLE ?? "",
    libraries: libraries,
  });
  const options = {
    mapTypeControl: false, // Hide map type control (including Satellite view)
    zoomControl: false, // Hide zoom control
    streetViewControl: false, // Hide street view control
    fullscreenControl: false, // Hide fullscreen control
  };
  return (
    <>
      { isLoaded &&
        <GoogleMap
          id="map"
          mapContainerStyle={{ width: "100%", height: "100%" }}
          zoom={15}
          center={center}
          options={options}
        >
          <MarkerF
            position={center}
            draggable={dragable}
            onDragEnd={(e) =>
              onMarkerDragEnd(e.latLng?.lat() ?? 0, e.latLng?.lng() ?? 0)
            }
          />
        </GoogleMap>
      }
    </>
  );
};

export default Maps;
