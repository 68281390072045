import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getAllDivision = async (params: any) => {
  const url = endpoints.DIVISION;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const getDetailDivision = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.DETAIL_DIVISION(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const createDivision = async (params: any) => {
  const url = endpoints.DIVISION;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteDivision = async (id: number) => {
  const url = endpoints.DETAIL_DIVISION(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateDivision = async (params: any) => {
  const url = endpoints.DETAIL_DIVISION(params?.data.id);
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const getDivisionByEmployee = async (params: any) => {
  const url = endpoints.BASE_API + "attendance/byemployee";
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });
  return response;
};

const service = {
  getAllDivision,
  getDetailDivision,
  createDivision,
  deleteDivision,
  updateDivision,
  getDivisionByEmployee
};

export default service;
